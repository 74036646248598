<template>
  <div class="header">
    <div class="container">
      <a class="site-logo" ><img src="../../../../assets/eft/layout/img/logos/logonuevo.png" alt="Inicio"/></a>
      <!-- <a href="javascript:void(0);" class="mobi-toggler">Menú</a> -->
      <div class="header-navigation pull-right font-transform-inherit">
        <!-- <ul>
          <li>
            <router-link active-class="active" to="/inicio">Inicio</router-link>
          </li>
          <li>
            <router-link active-class="active" to="/acerca">Conócenos</router-link>
          </li>
          <li>
            <router-link active-class="active" to="/transparencia">Trasparencia</router-link>
          </li>
          <li>
            <router-link active-class="active" to="/contacto">Contacto</router-link>
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "site-header",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="css">
</style>