<template>
  <div class="pre-header">
        <div class="container">
            <div class="row">
                <div class="wrapper">
                    <div class="col-md-6 col-sm-6 additional-shop-info">
                        <ul class="list-unstyled list-inline test">
                            <li><i class="fa fa-phone"></i><span>+55 777 77 77</span></li>
                            <li><i class="fa fa-envelope-o"></i><span>info@eoninnovaction.mx</span></li>
                        </ul>
                    </div>
                    <div class="col-md-6 col-sm-6 additional-nav">
                        <ul class="list-unstyled list-inline pull-right">
                            <li><router-link  to="/account">Log In</router-link></li>
                            <li><router-link  to="/registro">Registration</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="wrapper">
                    <div class="col-md-5">
                        <!-- <a class="logo pull-left" href="/" title="Inicio">
                            <img src="../../assets/icon/logonuevo.png" alt="Inicio">
                        </a> -->
                    </div>
                    <div class="col-md-3  hidden-xs hidden-sm">
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-10">
                                <div class="search-box hidden-xs hidden-sm clearfix">
                                    <form action="#">
                                        <div class="input-group">
                                            <input type="text" placeholder="Buscar" class="form-control">
                                            <span class="input-group-btn">
                                                <button class="btn btn-primary" type="submit"><span class="icon glyphicon glyphicon-search" aria-hidden="true"></span></button>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    name: "pre-header",
    data() {
      return {
        
      };
    },
    created() {
      
    },
    methods: {
      
    },
  };
</script>
<style lang="css">
   
</style>