<template>
   <div class="row profile">
      <div class="col-md-12">
         <div class="tabbable tabbable-custom tabbable-full-width">
            <ul class="nav nav-tabs">
               <li class="active">
                  <a href="#tab_visionGeneral" data-toggle="tab"> Visión General </a>
               </li>
               <li>
                  <a href="#tab_cuenta" data-toggle="tab">Cuenta</a>
               </li>
            </ul>
            <div class="tab-content">
               <div class="tab-pane active" id="tab_visionGeneral">
                  <div class="row">
                     <div class="col-md-3">
                        <ul class="list-unstyled profile-nav">
                           <li>
                              <img :src="ImagenProfile" class="img-responsive" alt="User Image"/>
                           </li>
                        </ul>
                     </div>
                     <div class="col-md-9">
                        <div class="row">
                           <div class="col-md-8 profile-info">
                              <h1>{{FullName}}</h1>
                              <ul class="list-inline">
                                 <li>
                                    <i class="fa fa-map-marker"></i> {{dependencia}}
                                 </li>
                                 <li>
                                    <i class="fa fa-calendar"></i> {{fechaNacimiento}}
                                 </li>
                                 <li>
                                    <i class="fa fa-briefcase"></i> {{CUIP}}
                                 </li>
                                 <li>
                                    <i class="fa fa-briefcase"></i> {{area}}
                                 </li>
                              </ul>
                           </div>
                           <div class="col-md-4">
                              <div class="portlet sale-summary">
                                 <div class="portlet-title">
                                    <div class="caption">
                                       Experiencia
                                    </div>
                                    <div class="tools">
                                       <a class="reload" href="javascript:;">
                                       </a>
                                    </div>
                                 </div>
                                 <div class="portlet-body">
                                    <ul class="list-unstyled">
                                       <li>
                                          <span class="sale-info">
                                          Competencia basicas <i class="fa fa-img-up"></i>
                                          </span>
                                          <span class="sale-num">
                                          100% </span>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="tab-pane" id="tab_cuenta">
                  <div class="row profile-account">
                     <div class="col-md-3">
                        <ul class="ver-inline-menu tabbable margin-bottom-10">
                           <li class="active">
                              <a data-toggle="tab" href="#tab_userInfo">
                              <i class="fa fa-cog"></i> Usuario info. </a>
                              <span class="after">
                              </span>
                           </li>
                           <!-- <li>
                              <a data-toggle="tab" href="#tab_changeAvatar">
                              <i class="fa fa-picture-o"></i> Change Avatar </a>
                           </li> -->
                           <li>
                              <a data-toggle="tab" href="#tab_setPassword">
                              <i class="fa fa-lock"></i> Contraseña </a>
                           </li>
                           <li>
                              <a data-toggle="tab" href="#tab_profile">
                              <i class="fa fa-eye"></i> Perfil</a>
                           </li>
                           <!-- <li>
                              <a data-toggle="tab" href="#tab_config">
                              <i class="fa fa-eye"></i> Configuraciones</a>
                           </li> -->
                        </ul>
                     </div>
                     <div class="col-md-9">
                        <div class="tab-content">
                           <div id="tab_userInfo" class="tab-pane active">
                              <component-user-info ref="personal-user-info"></component-user-info>
                           </div>

                           <div id="tab_setPassword" class="tab-pane">
                              <component-user-password  ref="personal-user-password"></component-user-password>
                           </div>

                           <div id="tab_profile" class="tab-pane">
                              <component-user-profile  ref="personal-user-profile"></component-user-profile>
                           </div>

                           <!-- <div id="tab_config" class="tab-pane">
                              <form action="#">
                                 <table class="table table-bordered table-striped">
                                    <tr>
                                       <td>
                                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus..
                                       </td>
                                       <td>
                                          <label class="uniform-inline">
                                          <input type="radio" name="optionsRadios1" value="option1"/>
                                          Yes </label>
                                          <label class="uniform-inline">
                                          <input type="radio" name="optionsRadios1" value="option2" checked/>
                                          No </label>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>
                                          Enim eiusmod high life accusamus terry richardson ad squid wolf moon
                                       </td>
                                       <td>
                                          <label class="uniform-inline">
                                          <input type="checkbox" value=""/> Yes </label>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>
                                          Enim eiusmod high life accusamus terry richardson ad squid wolf moon
                                       </td>
                                       <td>
                                          <label class="uniform-inline">
                                          <input type="checkbox" value=""/> Yes </label>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td>
                                          Enim eiusmod high life accusamus terry richardson ad squid wolf moon
                                       </td>
                                       <td>
                                          <label class="uniform-inline">
                                          <input type="checkbox" value=""/> Yes </label>
                                       </td>
                                    </tr>
                                 </table>
                                 <div class="margin-top-10">
                                    <a href="#" class="btn green">
                                    Save Changes </a>
                                    <a href="#" class="btn default">
                                    Cancel </a>
                                 </div>
                              </form>
                           </div> -->
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
  import { EventBus } from '../../../../../helpers/EventBus.js';
  import avatar from '../../../../../assets/admin/pages/media/profile/avatar.png';
  import userInfo from './personal-user-info/personal-user-info.vue';
  import userPassword from './personal-user-password/personal-user-password.vue';
  import UserProfile from './personal-user-profile/personal-user-profile.vue'; 

  export default {
    name: "component-personal-profile",
    components: {
      'component-user-info':userInfo,
      'component-user-password':userPassword,
      'component-user-profile':UserProfile 
    },
    data() {
      return {
      };
    },

    computed:{

      personal(){
         return this.$store.getters['person/currentPersonal'];
      },

      dependencia(){
         return (typeof this.personal === 'undefined') || (typeof this.personal.CatPuestoDependencia.catDependencia === 'undefined') ? "" : this.personal.CatPuestoDependencia.catDependencia.nombre ;
      },

      fechaNacimiento(){
         return (typeof this.personal === 'undefined') ? "" : this.personal.fechaNacimiento ;
      },

      CUIP(){
         return (typeof this.personal === 'undefined') ? "" : this.personal.CUIP;
      },

      area(){
         return (typeof this.personal === 'undefined') || (typeof this.personal.CatArea === 'undefined')? "" : this.personal.CatArea.title ;
      },

      FullName(){
          return (typeof this.personal === 'undefined') ? "" : `${this.personal.nombre} ${this.personal.segundoNombre} ${this.personal.apaterno} ${this.personal.amaterno}`;
      },

      ImagenProfile(){
          return (typeof this.personal === 'undefined') ? avatar : this.personal.foto;
      }  
    },
    methods: {
      loadInfo(){

      } 
    },
    created() {
      /* EventBus
      .$on('personal-save', () => {
         
      }) */
    },
    beforeMount() {
       this.loadInfo();
    },
  };
</script>
<style lang="css">
  @import url("./personal-profile-full.css");
</style>