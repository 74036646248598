var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "login-form",
      attrs: { role: "form", model: _vm.form, rules: _vm.rules }
    },
    [
      _c("h3", [_vm._v("Olvidaste tu contraseña?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Ingrese su dirección de correo electrónico a continuación para restablecer su contraseña"
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-body" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "email" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-actions" },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("form")
                    }
                  }
                },
                [_vm._v("Login")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetForm("form")
                    }
                  }
                },
                [_vm._v("Cancelar")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }