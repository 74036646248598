import { AppDataProvider } from '../../providers/app-data-provider';
export const List = {
    namespaced: true,
    state: {
        PersonalrowsCount: undefined,
        personal: [],
        estatusUsuario: [],
        perfiles: []
    },
    getters: {
        personalRowsCount: state => state.PersonalrowsCount,
        personal: state => state.personal,
        estatusUsuario: state => state.estatusUsuario,
        perfiles: state => state.perfiles
    },
    mutations: {

        GET_PERSONAL_COUNT: (state, payload) => {
            state.PersonalrowsCount = payload;
        },

        GET_PERSONAL: (state, payload) => {
            state.personal = payload;
        },

        GET_ESTATUS_USUARIO: (state, payload) => {
            state.estatusUsuario = payload;
        },

        GET_PERFILES: (state, payload) => {
            state.perfiles = payload;
        },

    },
    actions: {
        getPersonalRoundCount: (context, payload) => {

            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                AppDataProvider.getCountRecords(payload, TOKEN)
                    .then(function(response) {
                        context.commit('GET_PERSONAL_COUNT', response[0].totalRecors);
                        resolve(response);
                    }).catch(function(error) {
                        reject(error);
                    });
            });
        },

        searchPersonal: (context, payload) => {

            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                AppDataProvider.searchPersonal(payload, TOKEN)
                    .then(function(response) {
                        context.commit('GET_PERSONAL', response);
                        resolve(response);
                    }).catch(function(error) {
                        reject(error);
                    });
            });
        },

        getPersonal: (context, payload) => {

            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                AppDataProvider.getPersonal(payload.idPersonal, TOKEN, payload.pageNumber, payload.rowsOfPage)
                    .then(function(response) {
                        context.commit('GET_PERSONAL', response);
                        resolve();
                    }).catch(function(error) {
                        reject(error);
                    });
            });
        },

        getEstatusUsuario: (context, payload) => {

            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                AppDataProvider.getEstatusUsuario(payload, TOKEN)
                    .then(function(response) {
                        context.commit('GET_ESTATUS_USUARIO', response);
                        resolve();
                    }).catch(function(error) {
                        reject(error);
                    });
            });
        },

        getPerfiles: (context, payload) => {

            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                AppDataProvider.getPerfiles(payload, TOKEN)
                    .then(function(response) {
                        context.commit('GET_PERFILES', response);
                        resolve();
                    }).catch(function(error) {
                        reject(error);
                    });
            });
        },
    }
};