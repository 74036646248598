export const funcionalidades = {
    Core: {
        funcionalidades: [{
                "Caption": "Admin. Mediafiliacion",
                "Icon": "",
                "Name": "/AdminMediafiliacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estados",
                "Icon": "",
                "Name": "/AdminEstados",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Icono",
                "Icon": "",
                "Name": "/AdminIcono",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Marcas",
                "Icon": "",
                "Name": "/AdminMarcas",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Modelos",
                "Icon": "",
                "Name": "/AdminModelos",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Municipios",
                "Icon": "",
                "Name": "/AdminMunicipios",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Rasgos",
                "Icon": "",
                "Name": "/AdminRasgos",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Subcategoria",
                "Icon": "",
                "Name": "/AdminSubcategoria",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Instancias",
                "Icon": "",
                "Name": "/AdminInstancias",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Categorias",
                "Icon": "",
                "Name": "/AdminCategorias",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Colonias",
                "Icon": "",
                "Name": "/AdminColonias",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Color Vehículo",
                "Icon": "",
                "Name": "/AdminColorVehiculo",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Dependencias",
                "Icon": "",
                "Name": "/AdminDependencias",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Seguimiento",
                "Icon": "",
                "Name": "/AdminCatSeguimiento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Grado Homologado",
                "Icon": "",
                "Name": "/AdminCatGradoHomologado",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Ocupación",
                "Icon": "",
                "Name": "/AdminCatOcupacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Sexo",
                "Icon": "",
                "Name": "/AdminCatSexo",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Función",
                "Icon": "",
                "Name": "/AdminCatTipoFuncion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Permutación",
                "Icon": "",
                "Name": "/AdminCatTipoPermutacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Puesto",
                "Icon": "",
                "Name": "/AdminCatTipoPuesto",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Referencia",
                "Icon": "",
                "Name": "/AdminCatTipoReferencia",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Señas Particulares",
                "Icon": "",
                "Name": "/AdminTipoSP",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Lado Señas Particulares",
                "Icon": "",
                "Name": "/AdminLadoSP",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Región Señas Particulares",
                "Icon": "",
                "Name": "/AdminRegionSP",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Vista Señas Particulares",
                "Icon": "",
                "Name": "/AdminVistaSP",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. SubÁrea",
                "Icon": "",
                "Name": "/AdminSubArea",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
        ]
    },
    comun: {
        funcionalidades: [{
                "Caption": "Admin. Tipo Vehículo",
                "Icon": "",
                "Name": "/AdminTipoVehiculo",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Uso Vehículo",
                "Icon": "",
                "Name": "/AdminUsoVehiculo",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
        ]
    },
    EstadoFuerza: {
        funcionalidades: [{
                "Caption": "Admin. Tipo Habilidad",
                "Icon": "",
                "Name": "/AdminTipoHabilidad",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Documentos",
                "Icon": "",
                "Name": "/AdminCatDocumentos",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Estudio",
                "Icon": "",
                "Name": "/AdminCatEstatusEstudio",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estudios",
                "Icon": "",
                "Name": "/AdminCatEstudios",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Turno",
                "Icon": "",
                "Name": "/AdminCatTurno",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Recurso",
                "Icon": "",
                "Name": "/AdminCatTipoRecurso",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Nacionalidad",
                "Icon": "",
                "Name": "/AdminCatTipoNacionalidad",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Falta Administrativa",
                "Icon": "",
                "Name": "/AdminCatTipoFaltaAdministrativa",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Biometrico",
                "Icon": "",
                "Name": "/AdminCatTipoBiometrico",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Ambito",
                "Icon": "",
                "Name": "/AdminCatTipoAmbito",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Bien Juridico",
                "Icon": "",
                "Name": "/AdminCatBienJuridico",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Comprobante Estudio",
                "Icon": "",
                "Name": "/AdminComprobanteEstudio",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estado Civil",
                "Icon": "",
                "Name": "/AdminEstadoCivil",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Documentos",
                "Icon": "",
                "Name": "/AdminEstatusDocumentos",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Proceso",
                "Icon": "",
                "Name": "/AdminEstatusProceso",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Ubicacion",
                "Icon": "",
                "Name": "/AdminEstatusUbicacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Habilitado",
                "Icon": "",
                "Name": "/AdminHabilitado",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Modalidad",
                "Icon": "",
                "Name": "/AdminModalidad",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Motivo Baja",
                "Icon": "",
                "Name": "/AdminMotivoBaja",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Motivo Falta Administrativa",
                "Icon": "",
                "Name": "/AdminMotivoFaltaAdministrativa",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Nivel",
                "Icon": "",
                "Name": "/AdminNivel",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
        ]
    },
    Armeria: {
        funcionalidades: [{
                "Caption": "Admin. Calibre",
                "Icon": "",
                "Name": "/AdminCalibre",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Clase",
                "Icon": "",
                "Name": "/AdminClase",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Arma",
                "Icon": "",
                "Name": "/AdminEstatusArma",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Municion",
                "Icon": "",
                "Name": "/AdminEstatusMunicion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Item",
                "Icon": "",
                "Name": "/AdminItem",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Marca Arma",
                "Icon": "",
                "Name": "/AdminMarcaArma",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Modelo Arma",
                "Icon": "",
                "Name": "/AdminModeloArma",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Motivo Uso",
                "Icon": "",
                "Name": "/AdminMotivoUso",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Procedencia Arma",
                "Icon": "",
                "Name": "/AdminProcedenciaArma",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Arma",
                "Icon": "",
                "Name": "/AdminTipoArma",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Asignacion",
                "Icon": "",
                "Name": "/AdminTipoAsignacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Entrega",
                "Icon": "",
                "Name": "/AdminTipoEntrega",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Item",
                "Icon": "",
                "Name": "/AdminTipoItem",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
        ]
    },
    DesarrolloPolicial: {
        funcionalidades: [{
                "Caption": "Admin. Actos Relevantes",
                "Icon": "",
                "Name": "/AdminActosRelevantes",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Condecoraciones",
                "Icon": "",
                "Name": "/AdminCondecoraciones",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Criterio Evaluación",
                "Icon": "",
                "Name": "/AdminCriterioEvaluacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Descripción",
                "Icon": "",
                "Name": "/AdminDescripcion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Evaluación",
                "Icon": "",
                "Name": "/AdminEstatusEvaluacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Procedimiento",
                "Icon": "",
                "Name": "/AdminEstatusProcedimiento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Frecuencia Productividad",
                "Icon": "",
                "Name": "/AdminFrecuenciaProductividad",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Frecuencia Respeto Principios",
                "Icon": "",
                "Name": "/AdminFrecuenciaRespetoPrincipios",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Funciones",
                "Icon": "",
                "Name": "/AdminFunciones",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Habilidades",
                "Icon": "",
                "Name": "/AdminHabilidades",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Incidencia",
                "Icon": "",
                "Name": "/AdminIncidencia",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Indicador",
                "Icon": "",
                "Name": "/AdminIndicador",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Periodicidad",
                "Icon": "",
                "Name": "/AdminPeriodicidad",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Principio",
                "Icon": "",
                "Name": "/AdminPrincipio",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Principios Constitucionales",
                "Icon": "",
                "Name": "/AdminPrincipiosConstitucionales",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Procedimiento",
                "Icon": "",
                "Name": "/AdminProcedimiento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Reactivo",
                "Icon": "",
                "Name": "/AdminReactivo",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Resultado",
                "Icon": "",
                "Name": "/AdminResultado",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Convocatoria",
                "Icon": "",
                "Name": "/AdminTipoConvocatoria",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Evaluación",
                "Icon": "",
                "Name": "/AdminTipoEvaluacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Recompensa",
                "Icon": "",
                "Name": "/AdminTipoRecompensa",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Valores",
                "Icon": "",
                "Name": "/AdminValores",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Valores Capacidades",
                "Icon": "",
                "Name": "/AdminValoresCapacidades",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Reactivo Principio",
                "Icon": "",
                "Name": "/AdminReactivoPrincipio",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Reactivo Valores",
                "Icon": "",
                "Name": "/AdminReactivoValores",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Indicador Evaluación",
                "Icon": "",
                "Name": "/AdminIndicadorEvaluacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Actos Relevantes Evaluación",
                "Icon": "",
                "Name": "/AdminActosRelevantesEvaluacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Descripción Criterio",
                "Icon": "",
                "Name": "/AdminDescripcionCriterio",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Reactivo Evaluación",
                "Icon": "",
                "Name": "/AdminReactivoEvaluacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
        ]
    },
    Equipamiento: {
        funcionalidades: [{
                "Caption": "Admin. Estatus Equipo",
                "Icon": "",
                "Name": "/AdminEstatusEquipo",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Movimiento Equipo",
                "Icon": "",
                "Name": "/AdminEstatusMovimientoEquipo",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Item Equipamiento",
                "Icon": "",
                "Name": "/AdminItemEquipamiento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Item Equipamiento",
                "Icon": "",
                "Name": "/AdminTipoItemEquipamiento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Movimiento",
                "Icon": "",
                "Name": "/AdminTipoMovimiento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
        ]
    },
    Profesionalizacion: {
        funcionalidades: [{
                "Caption": "Admin. Aulas",
                "Icon": "",
                "Name": "/AdminAulas",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Capacitadores",
                "Icon": "",
                "Name": "/AdminCapacitadores",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Categoria",
                "Icon": "",
                "Name": "/AdminCategoria",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Resultado Curso",
                "Icon": "",
                "Name": "/AdminResultadoCurso",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Aula",
                "Icon": "",
                "Name": "/AdminEstatusAula",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Curso",
                "Icon": "",
                "Name": "/AdminEstatusCurso",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Curso",
                "Icon": "",
                "Name": "/AdminCurso",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Curso",
                "Icon": "",
                "Name": "/AdminTipoCurso",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
        ]
    },
    Alertamiento: {
        funcionalidades: [{
                "Caption": "Admin. Tipo de Aduana",
                "Icon": "",
                "Name": "/AdminTipoAduana",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Aerolinea",
                "Icon": "",
                "Name": "/AdminAerolinea",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Aeropuerto",
                "Icon": "",
                "Name": "/AdminAeropuerto",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. AElemento Alertado",
                "Icon": "",
                "Name": "/AdminElementoAlertado",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Ticket Alertamiento",
                "Icon": "",
                "Name": "/AdminEstatusTicketAlertamiento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Evento",
                "Icon": "",
                "Name": "/AdminEvento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Lugar de Revisión",
                "Icon": "",
                "Name": "/AdminLugarRevision",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Motivo Cierre Alertamiento",
                "Icon": "",
                "Name": "/AdminMotivoCierreAlertamiento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Nacionalidad",
                "Icon": "",
                "Name": "/AdminNacionalidad",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Presuncion de Riesgo",
                "Icon": "",
                "Name": "/AdminPresuncionRiesgo",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Producto",
                "Icon": "",
                "Name": "/AdminProducto",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Puerto",
                "Icon": "",
                "Name": "/AdminPuerto",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Subtipo de Incidencia",
                "Icon": "",
                "Name": "/AdminSubtipoIncidencia",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tigie",
                "Icon": "",
                "Name": "/AdminTigie",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Contenedor",
                "Icon": "",
                "Name": "/AdminTipoContenedor",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Correo",
                "Icon": "",
                "Name": "/AdminTipoCorreo",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Documento Alertamiento",
                "Icon": "",
                "Name": "/AdminTipoDocumentoAlertamiento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Formato",
                "Icon": "",
                "Name": "/AdminTipoFormato",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Incidencia",
                "Icon": "",
                "Name": "/AdminTipoIncidencia",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Inmueble",
                "Icon": "",
                "Name": "/AdminTipoInmueble",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Mercancia",
                "Icon": "",
                "Name": "/AdminTipoMercancia",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Operacion",
                "Icon": "",
                "Name": "/AdminTipoOperacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Participacion",
                "Icon": "",
                "Name": "/AdminTipoParticipacion",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Rol",
                "Icon": "",
                "Name": "/AdminTipoRol",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Transporte",
                "Icon": "",
                "Name": "/AdminTipoTransporte",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Unidad de Medida Alertamiento",
                "Icon": "",
                "Name": "/AdminUnidadMedidaAlertamiento",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
        ]
    },
    Canino: {
        funcionalidades: [{
                "Caption": "Admin. Categoria de Canino",
                "Icon": "",
                "Name": "/AdminCategoriaCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Color de Canino",
                "Icon": "",
                "Name": "/AdminColorCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Condecoracion de Canino",
                "Icon": "",
                "Name": "/AdminCondecoracionCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus de Canino",
                "Icon": "",
                "Name": "/AdminEstatusCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Curso Canino",
                "Icon": "",
                "Name": "/AdminEstatusCursoCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus Medico Canino",
                "Icon": "",
                "Name": "/AdminEstatusMedicoCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Estatus de Vacuna",
                "Icon": "",
                "Name": "/AdminEstatusVacuna",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Raza",
                "Icon": "",
                "Name": "/AdminRaza",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Resultado de Curso Canino",
                "Icon": "",
                "Name": "/AdminResultadoCursoCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Sexo de Canino",
                "Icon": "",
                "Name": "/AdminSexoCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Subcategoria de Canino",
                "Icon": "",
                "Name": "/AdminSubcategoriaCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo Baja de Canino",
                "Icon": "",
                "Name": "/AdminTipoBajaCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Documento Canino",
                "Icon": "",
                "Name": "/AdminTipoDocumentoCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de Ingreso Canino",
                "Icon": "",
                "Name": "/AdminTipoIngresoCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Unidad de Medida Canino",
                "Icon": "",
                "Name": "/AdminUnidadMedidaCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            },
            {
                "Caption": "Admin. Tipo de MovimientoCan",
                "Icon": "",
                "Name": "/AdminTipoMovimientoCanino",
                "NameComponent": "",
                "NameComponentPrincipal": "mainSidebar",
                "Position": ""
            }
        ]
    },
};