<template>
  <div class="pre-footer">
    <div class="container">
      <div class="row">
        <!-- BEGIN BOTTOM ABOUT BLOCK -->
        <div class="col-md-4 col-sm-6 pre-footer-col">
          <h2>Sobre nosotros</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            sit nonummy nibh euismod tincidunt ut laoreet dolore magna aliquarm
            erat sit volutpat.
          </p>
        </div>
        <!-- END BOTTOM ABOUT BLOCK -->

        <!-- BEGIN BOTTOM CONTACTS -->
        <div class="col-md-4 col-sm-6 pre-footer-col">
          <h2>Nuestro Contacto</h2>
          <address class="margin-bottom-40">
            35, Lorem Lis Street, Park Ave<br />
            California, US<br />
            Phone: 300 323 3456<br />
            Fax: 300 323 1456<br />
            Email:
            <a href="mailto:info@eoninnovaction.mx">info@eoninnovaction.mx</a
            ><br />
            Skype: <a href="skype:eoninnovaction">Eon Innovaction</a>
          </address>
        </div>
        <!-- END BOTTOM CONTACTS -->

        <!-- BEGIN TWITTER BLOCK -->
        <div class="col-md-4 col-sm-6 pre-footer-col">
          <div
            class="pre-footer-subscribe-box pre-footer-subscribe-box-vertical"
          >
            <h2>Boletin informativo</h2>
            <p>
              Suscríbase a nuestro boletín de noticias y manténgase actualizado
              con las últimas noticias y ofertas.
            </p>
            <form action="#">
              <div class="input-group">
                <input
                  type="text"
                  placeholder="youremail@mail.com"
                  class="form-control"
                />
                <span class="input-group-btn">
                  <button class="btn btn-primary" type="submit">
                    Subscribe
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
        <!-- END TWITTER BLOCK -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "pre-footer",
    data() {
      return {};
    },
    created() {},
    methods: {},
  };
</script>
<style lang="css">
</style>