<template>
   <el-form ref="form" role="form" :model="form" :rules="rules" @submit.native.prevent  class="login-form" v-loading="loading">
      <h3 class="form-title">Ingrese a su cuenta</h3>
      <div class="alert alert-danger display-hide">
         <button class="close" data-close="alert"></button>
         <span> Ingrese su nombre de usuario y contraseña </span>
      </div>
      <div class="form-body">
         <el-form-item label="Usuario" prop="Usuario">
            <el-input v-model="form.Usuario"></el-input>
         </el-form-item>
         <el-form-item label="Password" prop="Password">
            <el-input type="password" v-model="form.Password" ></el-input>
         </el-form-item>
      </div>
      <hr>
      <div class="form-actions">
         <!-- <label class="checkbox">
            <input type="checkbox" name="remember" value="1" /> Remember me
            </label> -->
         <el-form-item>
            <el-button type="primary" @click="submitForm('form')" :loading="loadingButton" id="btnOK">Login</el-button>
            <el-button @click="resetForm('form')" >Cancelar</el-button>
         </el-form-item>
      </div>
      <transition name="fade">
        <span v-show="error.error_message" v-html="error.error_message"></span>
      </transition>
      <!-- <div class="login-options">
         <h4>Or login with</h4>
         <ul class="social-icons">
           <li>
             <a class="facebook" data-original-title="facebook" href="#"> </a>
           </li>
           <li>
             <a class="twitter" data-original-title="Twitter" href="#"> </a>
           </li>
           <li>
             <a class="googleplus" data-original-title="Goole Plus" href="#">
             </a>
           </li>
           <li>
             <a class="linkedin" data-original-title="Linkedin" href="#"> </a>
           </li>
         </ul>
         </div> -->
      <div class="forget-password">
         <h4>Olvidaste tu contraseña?</h4>
         <p>
            No te preocupes, haz clic
            <router-link  to="/forget-password" >aquí</router-link>
            para restablecer tu contraseña
         </p>
      </div>
   </el-form>
</template>
<script>
  import { EventBus } from '../../../../helpers/EventBus.js';
  import GLOBALS from "../../../../app/globals.ts";
  import {UserService} from '../../../../providers/user-service.js';
  let sha1 = require('sha1');
  
  export default {
    name: "page-login",
    data: function () {
      return {
        form: {
          Usuario: "",
          Password: "",
        },
        rules: {
          Usuario: [
            {
              required: true,
              message: "Por favor inserta el Usuario",
              trigger: "blur",
            },
          ],
          Password: [
            {
              required: true,
              message: "Por favor inserta el Password",
              trigger: "blur",
            },
          ],
        },
        loadingButton: false,
        messageOK: "",
        loading : false,
        error : ''
      };
    },

    computed: {
      getCurrentUser() {
        return this.$store.getters['account/getCurrentUser'];
      }
    },
  
    methods: {
      submitForm(formName) {
        let self = this;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingButton = true;
            this.loading = true;

            let formData = {
                usuario :this.form.Usuario,
                password:sha1(this.form.Password).toUpperCase()
            };

            this.$store.dispatch("account/doLogin", formData).
              then(function (User) {
                self.loading = false;
                self.loadingButton = false;
                self.$session.start();
                self.$session.set('UserIdentifier', User.token);
                self.$session.set('User', User);
                self.$router.push('/admin');
              })
              .catch(function (error) {
                self.loading = false;
                self.loadingButton = false;
                self.error = error;
                EventBus.$emit('error',error);
              });

          } else {
            return false;
          }
        });
      },

      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.loading = false;
        this.loadingButton = false;
        
        /* this.$router.push('/'); */
      }
    },

    created() {
      self = this;
      EventBus
      .$on('keyPressed-enter', () => {
          this.submitForm('form');
      });
    },

    beforeMount() {},
  };
</script>
<style lang="css">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }
          
  .fade-enter,
  .fade-leave-to
  /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }
</style>