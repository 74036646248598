<template>
  <div>
    <div class="clearfix margin-top-10" v-show="hasUser">
			<span class="label label-danger"> NOTE! </span>
			<span>&nbsp;&nbsp;No cuanta con usuario. </span>
		</div>
  
   <el-form :model="form" :rules="rules" ref="form" v-loading="loading">
      <el-form-item label="ID" prop="id_usuario">
         <el-input v-model="form.id_usuario" readonly></el-input>
      </el-form-item>
      <el-form-item label="Usuario" prop="username">
         <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item label="Estatus" prop="fkidestatus">
         <el-select v-model="form.fkidestatus" placeholder="Seleccione" class="w-100">
            <el-option v-for="e in estatus" :label="e.title" :value="e.id" :key="e.id"></el-option>
         </el-select>
      </el-form-item>
      <el-form-item>
         <el-button type="primary" @click.prevent="submitForm('form')">Guardar</el-button>
      </el-form-item>
   </el-form>
  </div>
</template>

<script>
import { EventBus } from '../../../../../../helpers/EventBus.js'
import {AppDataProvider} from '../../../../../../providers/app-data-provider.js';
import {PersonalService} from '../../../../../../providers/personal-service.js';
import {UserService} from '../../../../../../providers/user-service.js';

export default {
  name:"component-user-info",
  data: function () {
    return {
      form: {
        id_usuario:"",
        username: "",
        fkidestatus: ""
      },
      rules: {
        username: [
          {
            required: true,
            pattern:/([0-9A-Za-z])/,
            message: "Por favor inserta el usuario ",
            trigger: "blur",
          },
        ],
        fkidestatus: [
          {
            required: true,
            message: "Por favor Seleccione un estatus",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      messageOK: "",
    };
  },

  watch: {
    personal (val) {
      if(!(typeof val === 'undefined')){
        this.loadInfo();
        this.loadData();
      }
    }
  },

  computed: {
    estatus(){
      this.loading = false;
      return this.$store.getters['list/estatusUsuario'];
    },

    personal(){
        return this.$store.getters['person/currentPersonal'];
    },

    user(){
        return this.$store.getters['person/currentUser'];
    },

    hasUser (){
       return (typeof this.user === 'undefined');
    }
  },

  methods: {
    submitForm(formName) {
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;

          let formData = {
            /* id_usuario :this.form.id_usuario, */
            /* fk_idpersonal: this.personal.idPersonal */
            loginField:this.form.username,
            fk_idestatus:this.form.fkidestatus
          };

          /* UserService.save(formData) */
          this.$store.dispatch("person/saveUserPersonal", formData)
          .then(function (response) {
              self.$message({
                message: self.messageOK,
                type: "success",
              });
              self.loadInfo();
              self.loading = false;
          })
          .catch(function (error) {
              self.loading = false;
              EventBus.$emit('error',error);
          });

        } else {
          return false;
        }
      });
    },

    /* OK */
    resetForm(formName) {
      this.loading = false;
      this.form.id_usuario ="";
      this.form.username = "";
      this.form.fkidestatus = "";
    },

    calcelForm(formName) {
      this.resetForm('form');
    },

    loadData(){
      if(!(typeof this.user === 'undefined')){
        this.form.id_usuario = this.user.id;
        this.form.username = this.user.usuario;
        this.form.fkidestatus = this.user.fk_idEstatus;
      }
    },

    loadInfo(){
      this.resetForm('form');
      this.getEstatus();
      this.getUser();
    },

    getUser() {
      let self = this;
      if(typeof this.personal === 'undefined'){
        return;
      }
      this.loading = true;
      this.$store.dispatch("person/getUserPersonal", this.personal.idPersonal)
      .then(function (response) {
          //self.user = response.find(element => element.fk_idPersonal == self.personal.idPersonal);
          self.loadData();
          self.loading = false;
      })
      .catch(function (error) {
        self.loading = false;
        //EventBus.$emit('error',error);
          
      });
    },

    getEstatus() {
      try {
        this.loading = true;
        this.$store.dispatch("list/getEstatusUsuario");
      }
      catch(error) {
        this.loading = false;
        EventBus.$emit('error',error);
      }
    },
  },

  beforeMount() {
    this.loadInfo();
  }
};
</script>

<style>

</style>