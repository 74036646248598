const axios = require('axios').default;

export const Utils = {

    httpPost: function(url, body, Authorization, options) {
        if (!options) {
            let headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
            if (Authorization) {
                headers['Authorization'] = Authorization;
            }
            options = { headers: headers };
        }
        //console.log(url);

        return new Promise((resolve, reject) => {
            //excect accion
            axios.post(url, body, options)
                .then((response) => {
                    let data = response.data;
                    if (data.api_exception === '') {
                        resolve(data.data);
                    } else {
                        reject(`ocurrio el siguiente error: api_exception: ${data.api_exception} , error_code: ${data.error_code} error_message: ${data.error_message}`);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    httpPostTest: function(url, body, Authorization, options) {
        if (!options) {
            let headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
            if (Authorization) {
                headers['Authorization'] = Authorization;
            }
            options = { headers: headers };
        }
        //console.log(url);

        return new Promise((resolve, reject) => {
            //excect accion
            axios.post(url, body, options)
                .then((response) => {
                    resolve(response.data);
                    /* let data = response.data;
                    if (data.api_exception === '') {
                        resolve(data.data);
                    } else {
                        reject(`ocurrio el siguiente error: api_exception: ${data.api_exception} , error_code: ${data.error_code} error_message: ${data.error_message}`);
                    } */
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    httpGet: function(url, Authorization, options) {
        let self = this;
        if (!options) {
            let headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
            if (Authorization) {
                headers['Authorization'] = Authorization;
            }
            options = { headers: headers };
        }

        return new Promise((resolve, reject) => {
            //excect accion
            axios.get(url, options)
                .then((response) => {
                    //console.log(response);
                    let data = response.data;
                    if (data.api_exception === '') {
                        resolve(data.data);
                    } else {
                        reject(`ocurrio el siguiente error: api_exception: ${data.api_exception} , error_code: ${data.error_code} error_message: ${data.error_message}`);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    httpUpdate: function(url, body, Authorization, options) {
        if (!options) {
            let headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
            if (Authorization) {
                headers['Authorization'] = Authorization;
            }
            options = { headers: headers };
        }

        return new Promise((resolve, reject) => {
            //excect accion
            axios.put(url, body, options)
                .then((response) => {
                    let data = response.data;
                    if (data.api_exception === '') {
                        resolve(data.data);
                    } else {
                        reject(`ocurrio el siguiente error: api_exception: ${data.api_exception} , error_code: ${data.error_code} error_message: ${data.error_message}`);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    httpDelete: function(url, Authorization, options) {
        if (!options) {
            let headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
            if (Authorization) {
                headers['Authorization'] = Authorization;
            }
            options = { headers: headers };
        }

        return new Promise((resolve, reject) => {
            //excect accion
            axios.delete(url, options)
                .then((response) => {
                    let data = response.data;
                    if (data.api_exception === '') {
                        resolve(data.data);
                    } else {
                        reject(`ocurrio el siguiente error: api_exception: ${data.api_exception} , error_code: ${data.error_code} error_message: ${data.error_message}`);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
}