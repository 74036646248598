<template>
  <div>
    <!-- class="corporate" -->
      <!-- <pre-header></pre-header>-->
      <site-header></site-header> 
      <!-- <router-view name='portal-content'></router-view> -->
      <!-- <pre-footer></pre-footer>
      <site-footer></site-footer> -->
      <div class="main">


        <!-- BEGIN SIDEBAR & CONTENT -->
        <div class="row margin-bottom-40">
          <!-- BEGIN CONTENT -->
          <div class="col-md-12 col-sm-12">
            <div class="content-page">
              <div class="row">
                <!-- BEGIN SERVICE BLOCKS -->               
                <div class="col-md-12">
                  <div class="row margin-bottom-20">
                    <div class="col-md-6">
                      <div class="service-box-v1" v-on:click="$router.push('/Core/admin');">
                        <div><i class="fa fa-cog color-grey"></i></div>
                        <h2>Administración Core</h2>
                        <!-- <p>Administración de catálogos basicos.</p> -->
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="service-box-v1" v-on:click="$router.push('/comun/admin');">
                        <div><i class="fa fa-globe color-grey"></i></div>
                        <h2>Administración Común</h2>
                        <!-- <p>Administración de catálogos transversales.</p> -->
                      </div>
                    </div>
                  </div>
                  <div class="row margin-bottom-20">
                    <div class="col-md-6">
                      <div class="service-box-v1" v-on:click="$router.push('/EstadoFuerza/admin');">
                        <div><i class="fa fa-user-o color-grey"></i></div>
                        <h2>Administración Estado de Fuerza</h2>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="service-box-v1" v-on:click="$router.push('/Armeria/admin');">
                        <div><i class="fa fa-bomb color-grey"></i></div>
                        <h2>Administración Armeria</h2>
                      </div>
                    </div>
                  </div>
                  <div class="row margin-bottom-20">
                    <div class="col-md-6">
                      <div class="service-box-v1" v-on:click="$router.push('/DesarrolloPolicial/admin');">
                        <div><i class="fa fa-certificate color-grey"></i></div>
                        <h2>Administración Desarrollo policial</h2>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="service-box-v1" v-on:click="$router.push('/Equipamiento/admin');">
                        <div><i class="fa fa-cog color-grey"></i></div>
                        <h2>Administración Equipamiento</h2>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="service-box-v1" v-on:click="$router.push('/Profesionalizacion/admin');">
                        <div><i class="fa fa-graduation-cap color-grey"></i></div>
                        <h2>Administración Profesionalización</h2>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="service-box-v1" v-on:click="$router.push('/Alertamiento/admin');">
                        <div><i class="fa fa-exclamation-triangle color-grey"></i></div>
                        <h2>Administración Alertamiento</h2>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="service-box-v1" v-on:click="$router.push('/Canino/admin');">
                        <div><i class="fa fa-paw color-grey"></i></div>
                        <h2>Administración Caninos</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END SERVICE BLOCKS --> 
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { EventBus } from "../../helpers/EventBus.js";
/* site-master*/
import PreHeader from "./site-master/pre-header/pre-header.vue";
import Header from "./site-master/header/header.vue";
import PreFooter from "./site-master/pre-footer/pre-footer.vue";
import Footer from "./site-master/footer/footer.vue";

export default {
  name: "portal",
  components: {
    "pre-header": PreHeader,
    "site-header": Header,
    "pre-footer": PreFooter,
    "site-footer": Footer,
  },
  data() {
    return {};
  },
  created() {
    EventBus.$emit("change-page", {
      stylecurrentpage: "corporate",
      currentpage: "portal",
    });
  },
  methods: {},
};
</script>

<style lang="css">
@import url("./portal.css");
@import url("./portal-responsive.css");
</style>