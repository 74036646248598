import { PersonalService } from '../../providers/personal-service.js';
import { UserService } from '../../providers/user-service.js';
export const Personal = {
    namespaced: true,
    state: {
        people: undefined,
        user: undefined,
        profiles: undefined
    },
    getters: {
        currentPersonal: state => state.people,
        currentUser: state => state.user,
        currentUserPerfiles: state => state.profiles
    },
    mutations: {

        SET_PERSONAL: (state, payload) => {
            state.people = payload;
        },
        DELETE_PERSONAL: (state, payload) => {
            state.people = undefined;
            state.user = undefined;
        },
        SET_USER: (state, payload) => {
            state.user = payload;
        },
        SET_PROFILES: (state, payload) => {
            state.profiles = payload;
        },
    },
    actions: {

        setCurrentPersonal: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                if ((typeof(payload) === 'undefined') || (payload.idPersonal == 0 || typeof(payload.idPersonal) === 'undefined')) {
                    reject('El record enviado no contiene información valida');
                }
                context.commit('SET_PERSONAL', payload);
                resolve();
            });
        },

        deleteCurrentPersonal: (context, payload) => {
            return new Promise((resolve, reject) => {
                context.commit('DELETE_PERSONAL', payload);
                resolve();
            });
        },

        getUserPersonal: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                PersonalService.getUser(payload, TOKEN).
                then(function(response) {
                    let usr = response.find(element => element.fk_idPersonal == payload);
                    context.commit('SET_USER', usr);
                    resolve();
                }).catch(function(error) {
                    //Nota:
                });
            });
        },

        saveUserPersonal: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                let person = context.rootGetters['person/currentPersonal'];
                let User = context.rootGetters['person/currentUser'];
                PersonalService.saveUser(payload, person.idPersonal, (typeof User === 'undefined') ? undefined : User.id, TOKEN).
                then(function(response) {
                    //let usr = response.find(element => element.fk_idPersonal == payload);
                    //context.commit('SET_USER', usr);
                    //context.dispatch('getUserPersonal', person.idPersonal);
                    resolve();
                }).catch(function(error) {
                    reject(error);
                });
            });
        },

        setPasswordUserPersonal: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                let User = context.rootGetters['person/currentUser'];
                UserService.setPassword(payload, User.id, TOKEN).
                then(function(response) {
                    resolve();
                }).catch(function(error) {
                    reject(error);
                });
            });
        },

        getProfilesUser: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                let User = context.rootGetters['person/currentUser'];
                PersonalService.getProfiles(User.id, TOKEN).
                then(function(response) {
                    let profiles = response
                    context.commit('SET_PROFILES', profiles);
                    resolve(profiles);
                }).catch(function(error) {
                    //Nota:
                });
            });
        },

        addProfile: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                let User = context.rootGetters['person/currentUser'];
                if ((typeof(payload) === 'undefined') || (payload.fk_idPerfil == 0 || typeof(payload.fk_idPerfil) === 'undefined')) {
                    reject('El record enviado no contiene información valida');
                }

                PersonalService.addProfiles(payload, User.id, TOKEN).
                then(function(response) {
                    context.dispatch('getProfilesUser');
                    resolve();
                }).catch(function(error) {
                    reject('El record enviado no contiene información valida');
                });
            });
        },

        deleteProfile: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                let User = context.rootGetters['person/currentUser'];
                let Perfiles = context.rootGetters['person/currentUserPerfiles'];
                if ((typeof(payload) === 'undefined') || (payload.fk_idPerfil == 0 || typeof(payload.fk_idPerfil) === 'undefined')) {
                    reject('El record enviado no contiene información valida');
                }

                let perfil = Perfiles.find(element => element.Perfil.id == payload.fk_idPerfil);
                payload.fk_idPerfil = perfil.idPerfilUsuario;

                PersonalService.deleteProfiles(payload, User.id, TOKEN).
                then(function(response) {
                    context.dispatch('getProfilesUser');
                    resolve();
                }).catch(function(error) {
                    reject('El record enviado no contiene información valida');
                });
            });
        },
    }
};