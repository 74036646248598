import Vue from "vue";
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import Portal from '../pages/portal/portal.vue';
import Account from '../pages/account/account.vue';
import Admin from '../pages/admin/admin.vue';

/* pages account*/
import Login from '../pages/account/components/login/login.vue';
import ForgetPassword from '../pages/account/components/forget-password/forget-password.vue';

/* pages Admin*/
import AdminPersonal from '../pages/admin/components/admin-personal/admin-personal.vue';
import AdminCatalogo from '../pages/admin/components/admin-catalogo/admin-catalogo.vue';

export default new VueRouter({
    routes: [

        {
            path: '/',
            redirect: '/launcher'
        },
        {
            path: '/account',
            components: { site: Account },
            children: [
                { path: '/login', components: { 'account-content': Login } },
                { path: '/forget-password', components: { 'account-content': ForgetPassword } },
            ]
        },
        {
            path: '/launcher',
            components: { site: Portal },
        },
        {
            path: '/Core/admin',
            components: { site: Admin },
            children: [
                { path: '/AdminMediafiliacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstados', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminIcono', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMarcas', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminModelos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMunicipios', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminRasgos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSubcategoria', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminInstancias', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCategorias', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminColonias', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminColorVehiculo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminDependencias', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatSeguimiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatGradoHomologado', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatOcupacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatSexo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoFuncion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoPermutacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoPuesto', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoReferencia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoSP', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminLadoSP', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminRegionSP', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminVistaSP', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCantidadSP', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSubArea', components: { 'admin-content': AdminCatalogo } }
            ]
        },
        {
            path: '/comun/admin',
            components: { site: Admin },
            children: [
                { path: '/AdminTipoVehiculo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminUsoVehiculo', components: { 'admin-content': AdminCatalogo } }
            ]
        },
        {
            path: '/EstadoFuerza/admin',
            components: { site: Admin },
            children: [
                { path: '/AdminTipoHabilidad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatDocumentos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatEstatusEstudio', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatEstudios', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTurno', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoRecurso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoNacionalidad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoFaltaAdministrativa', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoBiometrico', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoAmbito', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatBienJuridico', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminComprobanteEstudio', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstadoCivil', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusDocumentos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusProceso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusUbicacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminHabilitado', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminModalidad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMotivoBaja', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMotivoFaltaAdministrativa', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminNivel', components: { 'admin-content': AdminCatalogo } }
            ]
        },
        {
            path: '/Armeria/admin',
            components: { site: Admin },
            children: [
                { path: '/AdminCalibre', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminClase', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusArma', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusMunicion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminItem', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMarcaArma', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminModeloArma', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMotivoUso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminProcedenciaArma', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoArma', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoAsignacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoEntrega', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoItem', components: { 'admin-content': AdminCatalogo } }
            ]
        },
        {
            path: '/DesarrolloPolicial/admin',
            components: { site: Admin },
            children: [
                { path: '/AdminActosRelevantes', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCondecoraciones', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCriterioEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminDescripcion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusProcedimiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminFrecuenciaProductividad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminFrecuenciaRespetoPrincipios', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminFunciones', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminHabilidades', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminIncidencia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminIndicador', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPeriodicidad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPrincipio', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPrincipiosConstitucionales', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminProcedimiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminReactivo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminResultado', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoConvocatoria', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoRecompensa', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminValores', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminValoresCapacidades', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminReactivoPrincipio', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminReactivoValores', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminIndicadorEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminActosRelevantesEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminDescripcionCriterio', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminReactivoEvaluacion', components: { 'admin-content': AdminCatalogo } }
            ]
        },
        {
            path: '/Equipamiento/admin',
            components: { site: Admin },
            children: [
                { path: '/AdminEstatusEquipo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusMovimientoEquipo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminItemEquipamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoItemEquipamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoMovimiento', components: { 'admin-content': AdminCatalogo } }
            ]
        },
        {
            path: '/Profesionalizacion/admin',
            components: { site: Admin },
            children: [
                { path: '/AdminAulas', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCapacitadores', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCategoria', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminResultadoCurso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusAula', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusCurso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCurso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoCurso', components: { 'admin-content': AdminCatalogo } }
            ]
        },
        {
            path: '/Alertamiento/admin',
            components: { site: Admin },
            children: [
                { path: '/AdminTipoAduana', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminAerolinea', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminAeropuerto', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminElementoAlertado', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusTicketAlertamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEvento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminLugarRevision', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMotivoCierreAlertamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminNacionalidad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPresuncionRiesgo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminProducto', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPuerto', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSubtipoIncidencia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTigie', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoContenedor', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoCorreo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoDocumentoAlertamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoFormato', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoIncidencia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoInmueble', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoMercancia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoOperacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoParticipacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoRol', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoTransporte', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminUnidadMedidaAlertamiento', components: { 'admin-content': AdminCatalogo } }
            ]
        },
        {
            path: '/Canino/admin',
            components: { site: Admin },
            children: [
                { path: '/AdminCategoriaCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminColorCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCondecoracionCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusCursoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusMedicoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusVacuna', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminRaza', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminResultadoCursoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSexoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSubcategoriaCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoBajaCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoDocumentoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoIngresoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminUnidadMedidaCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoMovimientoCanino', components: { 'admin-content': AdminCatalogo } }
            ]
        },
        /* {
            path: '/admin',
            components: { site: Admin },
            children: [
                { path: '/adminPersonal', components: { 'admin-content': AdminPersonal } },
                { path: '/AdminDependencias', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCantidadSP', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminLadoSP', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminRegionSP', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoSP', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminVistaSP', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminArea', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminInstancias', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPuestos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPuestosDependencia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoHabilidad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminRamasDependencia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoRegion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatRegion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoSede', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatSede', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatSubSedes', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatDocumentos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatEstatusEstudio', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatEstudios', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMarcas', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminModelos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCategorias', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminColonias', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminColorVehiculo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMediafiliacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstados', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminIcono', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMunicipios', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminRasgos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPerfiles', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPreguntas', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSubcategoria', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminUsuarios', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPerfilesApp', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPerfilesUsuario', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPermisosApp', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPermisosPerfil', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoVehiculo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminUsoVehiculo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminDelitos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminDenuncias', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminHechos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatSeguimiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatGradoHomologado', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatOcupacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatSexo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoFuncion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatEstatusUsuario', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoPermutacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoPuesto', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoReferencia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTurno', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoRecurso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoNacionalidad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoFaltaAdministrativa', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoBiometrico', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatTipoAmbito', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCatBienJuridico', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminComprobanteEstudio', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminDelitoCes', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSubDelitoCes', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstadoCivil', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusDocumentos', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusProceso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusUbicacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminHabilitado', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminModalidad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMotivoBaja', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMotivoFaltaAdministrativa', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminNivel', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCalibre', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminClase', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusArma', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusCargador', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusMunicion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMarcaArma', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminModeloArma', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMotivoUso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminProcedenciaArma', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoArma', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoAsignacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoEntrega', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoItem', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminItem', components: { 'admin-content': AdminCatalogo } }, //---> Catalogo
                { path: '/AdminActosRelevantes', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCondecoraciones', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusProcedimiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminFrecuenciaProductividad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminFrecuenciaRespetoPrincipios', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminFunciones', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminIncidencia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminIndicador', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPeriodicidad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPrincipio', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminProcedimiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminReactivo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminResultado', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoConvocatoria', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoRecompensa', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminValores', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminReactivoPrincipio', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminReactivoValores', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminIndicadorEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminActosRelevantesEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCriterioEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminDescripcion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminHabilidades', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPrincipiosConstitucionales', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminReactivoEvaluacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminDescripcionCriterio', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminValoresCapacidades', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusEquipo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusMovimientoEquipo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminItemEquipamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoItemEquipamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoMovimiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminAulas', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCapacitadores', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCategoria', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminResultadoCurso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusAula', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusCurso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCurso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoCurso', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSubArea', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminAerolinea', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminAeropuerto', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminElementoAlertado', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusTicketAlertamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEvento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminLugarRevision', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminMotivoCierreAlertamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminNacionalidad', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPresuncionRiesgo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminProducto', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminPuerto', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSubtipoIncidencia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTigie', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoAduana', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoContenedor', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoCorreo', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoDocumentoAlertamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoFormato', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoIncidencia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoInmueble', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoMercancia', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoOperacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoParticipacion', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoRol', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoTransporte', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminUnidadMedidaAlertamiento', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCategoriaCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminColorCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminCondecoracionCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusCursoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusMedicoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminEstatusVacuna', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminRaza', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminResultadoCursoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSexoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminSubcategoriaCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoBajaCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoDocumentoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoIngresoCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminUnidadMedidaCanino', components: { 'admin-content': AdminCatalogo } },
                { path: '/AdminTipoMovimientoCanino', components: { 'admin-content': AdminCatalogo } }
            ]
        }, */
        /* {
            path: '*',
            component: Page404
        } */
    ]
});