<template>
   <div class="row">
      <div class="col-md-12">
         <div class="portlet box grey-cascade">
            <div class="portlet-title">
               <div class="caption">
                  <i class="fa fa-edit"></i>{{catalog.caption}}
               </div>
               <div class="tools">
                  <!-- <a href="javascript:;" class="collapse">
                     </a>
                     <a href="#portlet-config" data-toggle="modal" class="config">
                     </a>
                     <a href="javascript:;" class="reload">
                     </a>
                     <a href="javascript:;" class="remove">
                     </a> -->
               </div>
            </div>
            <div class="portlet-body" style="height:800px !important;overflow-y:auto !important;overflow-x: hidden !important">
               <div class="table-toolbar">
                  <div class="row">
                     <div class="col-md-6">
                        <div class="btn-group">
                           <el-button type="primary" icon="el-icon-plus"  circle @click="handleAdd()" > Agregar </el-button>
                        </div>
                        <el-dialog :title="catalog.caption" @close="closeModal" :visible.sync="dialogFormVisible">
                           <component-catalogo-form></component-catalogo-form>
                        </el-dialog>
                     </div>
                     <div class="col-md-6">
                        <div class="btn-group pull-right">
                        </div>
                     </div>
                  </div>
               </div>
               <div id="sample_editable_1_wrapper" class="dataTables_wrapper no-footer">
                  <div class="row">
                     <div class="col-md-6 col-sm-12">
                     </div>
                     <div class="col-md-6 col-sm-12">
                        <!-- <div id="sample_editable_1_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control input-small input-inline" aria-controls="sample_editable_1"></label></div> -->
                     </div>
                  </div>
                  <div class="table-scrollable">
                     <!-- :data="datos.filter(data => !search || datos.Discapacidad.toLowerCase().includes(search.toLowerCase()))" -->
                     <!-- :data="datos" -->
                     <el-table 
                        :data="list"
                        :cell-style="{padding: '0', height: '35px'}"
                        :header-row-style="{color: '#333333'}"
                        class=" table-striped table-hover table-bordered dataTable no-footer"
                        v-loading="loadingTable"
                        stripe>
                         <el-table-column v-for="column in showsColumns" 
                                          :key="column.label"
                                          :prop="column.propList"
                                          :label="column.label">
                        </el-table-column>
                        <el-table-column label="Operaciones" align="right">
                           <!-- <template slot="header" slot-scope="">
                           <el-input
                              v-model="search"
                              size="mini"
                              placeholder="Type to search"/>
                           </template> -->
                           <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" content="Actualizar" placement="bottom">
                                 <el-button type="primary" @click="handleUpdate(scope.$index, scope.row)" icon="el-icon-edit-outline" size="mini"></el-button>
                              </el-tooltip>
                              <el-tooltip class="item" effect="dark" content="Eliminar" placement="bottom">
                                 <el-button type="danger" @click="handleDelete(scope.$index, scope.row)" icon="el-icon-delete" size="mini"></el-button>
                              </el-tooltip>
                           </template>
                        </el-table-column>
                     </el-table>
                  </div>
                  <div class="row" v-if="pagination != null">         
                     <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="pagination.currentPage"
                        :page-sizes="[50, 100, 200, 300]"
                        :page-size="pagination.pagesize"
                        layout="sizes, prev, pager, next"
                        :total="pagination.totalRecords">
                     </el-pagination>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
  import { EventBus } from '../../../../helpers/EventBus.js';
  import CatalogForm from './component-catalogo-form/component-catalogo-form.vue';

  export default {
   name: "component-admin-catalogo",
   components: {
      'component-catalogo-form': CatalogForm,
   },
  
   data() {
      return {
        list:[],
        dialogFormVisible: false,
        search: "",
        loadingTable: false,
        messageOK: "",
        OPERATIONS:{
           ADD:1,
           EDIT:2,
           DELETE:3
        },
      }
   },

   watch: {
    catalog (val) {
      //if(!(typeof val === 'undefined')){
        this.loadInfo();
      //}
    }, 

  },


   computed: {
      catalog(){
         return this.$store.getters['jlcrud/catalog'];
      },

      piPrimaryKey(){
        return this.$store.getters['jlcrud/piPrimaryKey'];
      },

      showsColumns(){
          return this.$store.getters['jlcrud/showsColumns'];
      },

      datos() {
         return this.$store.getters['jlcrud/records'];
      },

      pagination() {
         return this.$store.getters['jlcrud/pagination'];
      },

      /* filterData() {

            return this.datos.filter(data => !this.search || data.nombre.toLowerCase().includes(this.search.toLowerCase()) || data.CUIP.toLowerCase().includes(this.search.toLowerCase()))
      } */
   },

   methods: {
      resetForm() {
         this.loading = false;
         this.list = [];
      },

      loadInfo(){
         this.load_data();
      },

      load_data() {
         let self = this;
         this.loadingTable = true;
         this.$store.dispatch("jlcrud/get", this.catalog.name) //
         .then(function (res) {
            self.loadingTable = false;
            self.list = res;
         })
         .catch(function (error) {
            self.loadingTable = false;
            EventBus.$emit('error',error);
         });
      }, 

      refresh() {
         let self = this;
         this.loadingTable = true;
         this.loadInfo();
         /* this.$store.dispatch("jlcrud/getAllRecords",this.catalog.name ) //
         .then(function (res) {
            self.loadingTable = false;
            self.list = res;
         })
         .catch(function (error) {
            self.loadingTable = false;
            EventBus.$emit('error',error);
         }); */
      }, 

      closeModal() {
         this.$store.dispatch("jlcrud/setCurrentRecord", undefined);
         this.$store.dispatch("jlcrud/setCurrentOperation", undefined);
         this.dialogFormVisible = false;
      },

      handleAdd(){
         //this.$store.dispatch("jlcrud/setCurrentRecord", undefined);
         this.$store.dispatch("jlcrud/setCurrentOperation", this.OPERATIONS.ADD);
         this.dialogFormVisible = true;

      },

      handleUpdate(index, row) {
         let record = this.datos.find(element => element[this.piPrimaryKey] == row[this.piPrimaryKey]);
         this.$store.dispatch("jlcrud/setCurrentRecord", record);
         this.$store.dispatch("jlcrud/setCurrentOperation", this.OPERATIONS.EDIT);
         this.dialogFormVisible = true;
      },

      handleDelete(index, row) {
         let self = this;
         let record = this.datos.find(element => element[this.piPrimaryKey] == row[this.piPrimaryKey]);
         this.$store.dispatch("jlcrud/setCurrentRecord", record);
         this.$store.dispatch("jlcrud/setCurrentOperation", this.OPERATIONS.DELETE);
         this.$store.dispatch("jlcrud/delete")
         .then(function (res) {
            self.loadingTable = false;
            self.list = res;
            self.$store.dispatch("jlcrud/setCurrentRecord", undefined);
            self.loadInfo();

            /* self.$store.dispatch("jlcrud/getAllRecords",self.catalog.name )
            .then(function (res) {
               self.loadingTable = false;
               self.list = res;
               self.$store.dispatch("jlcrud/setCurrentRecord", undefined);
            })
            .catch(function (error) {
               self.loadingTable = false;
               EventBus.$emit('error',error);
            }); */
            
         })
         .catch(function (error) {
            EventBus.$emit('error',error);
         });
      },

       handleSizeChange(val) {
        this.pagination.pagesize = val;
        this.pagination.currentPage = 1;
        this.loadInfo();
        
      },

      handleCurrentChange(val) {
        this.pagination.currentPage = val;
        this.loadInfo();
      },
   },

   created() {
      EventBus
      .$on('catalog-close', () => {
         this.closeModal();
      })
      .$on('catalog-save', () => {
         this.closeModal();
         this.refresh();
      });
   },

   beforeMount() {
      this.loadInfo();
   }

  };
</script>
<style lang="css">
  @import url("../../../../assets/global/plugins/datatables/plugins/bootstrap/dataTables.bootstrap.css");
</style>