var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "form",
          attrs: { model: _vm.form }
        },
        [
          _vm._l(_vm.form.items, function(item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.generateColumnName(item.field),
                      prop: item.fieldValue
                    }
                  },
                  [
                    item.isForeignKey
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "w-100",
                                attrs: { placeholder: "Seleccione" },
                                model: {
                                  value: item.fieldValue,
                                  callback: function($$v) {
                                    _vm.$set(item, "fieldValue", $$v)
                                  },
                                  expression: "item.fieldValue"
                                }
                              },
                              _vm._l(item.relation.Data, function(e) {
                                return _c("el-option", {
                                  key: e[
                                    item.relation.DataValueField
                                  ].toString(),
                                  attrs: {
                                    label: e[item.relation.DataTextField],
                                    value: e[
                                      item.relation.DataValueField
                                    ].toString()
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : item.fieldType == 3
                      ? _c("div", [_vm._v("\n          Date\n        ")])
                      : item.fieldType == 4
                      ? _c("div", [_vm._v("\n          DateTime\n        ")])
                      : _c(
                          "div",
                          [
                            _c("el-input", {
                              model: {
                                value: item.fieldValue,
                                callback: function($$v) {
                                  _vm.$set(item, "fieldValue", $$v)
                                },
                                expression: "item.fieldValue"
                              }
                            })
                          ],
                          1
                        )
                  ]
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("form")
                    }
                  }
                },
                [_vm._v("Guardar")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "info" },
                  on: {
                    click: function($event) {
                      return _vm.calcelForm("form")
                    }
                  }
                },
                [_vm._v("Cancelar")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }