<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <!-- BEGIN COPYRIGHT -->
        <div class="col-md-6 col-sm-6 padding-top-10">
          2019 © EON Innovaction. ALL Rights Reserved.
          <a href="#">Privacy Policy</a> | <a href="#">Terms of Service</a>
        </div>
        <!-- END COPYRIGHT -->
        <!-- BEGIN PAYMENTS -->
        <div class="col-md-6 col-sm-6">
          <ul class="social-footer list-unstyled list-inline pull-right">
            <li>
              <a href="#"><i class="fa fa-facebook"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-google-plus"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-dribbble"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-linkedin"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-twitter"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-skype"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-github"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-youtube"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-dropbox"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "site-footer",
    data() {
      return {};
    },
    created() {},
    methods: {},
  };
</script>
<style lang="css">
</style>