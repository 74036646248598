var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasUser,
              expression: "hasUser"
            }
          ],
          staticClass: "clearfix margin-top-10"
        },
        [
          _c("span", { staticClass: "label label-danger" }, [
            _vm._v(" NOTE! ")
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("  No cuanta con usuario. ")])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Contraseña", prop: "password" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.password,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Confirmar Contraseña", prop: "password" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.retrypassword,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "retrypassword", $$v)
                  },
                  expression: "form.retrypassword"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.comfirm,
                    loading: _vm.loadingBTN
                  },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("form")
                    }
                  }
                },
                [_vm._v("Guardar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }