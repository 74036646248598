<template>
  <div>
    <el-form :model="form"  ref="form" v-loading="loading">
      <div v-for="(item, index) in form.items" :key="index">
        <!-- <el-form-item :label="generateColumnName( index)" :prop="name"> -->
        <!-- <div v-if="item.">

        </div> -->
        <el-form-item :label="generateColumnName(item.field)" :prop="item.fieldValue">
          <!-- :rules="{required: true, message: 'description is required', trigger: 'blur'}" -->
          <!-- v-model="form[field]" -->
          <div v-if="item.isForeignKey">
            <el-select v-model="item.fieldValue" placeholder="Seleccione" class="w-100">
                <el-option v-for="e in item.relation.Data" :label="e[item.relation.DataTextField]" :value="e[item.relation.DataValueField].toString()" :key="e[item.relation.DataValueField].toString()"></el-option>
            </el-select>
          </div>
          <div v-else-if="item.fieldType == 3">
            Date
          </div>
          <div v-else-if="item.fieldType == 4">
            DateTime
          </div>
          <div v-else>
            <el-input  v-model="item.fieldValue"></el-input>
          </div>
        </el-form-item>
        <!-- <el-form-item label="Price" 
          :prop="'items.' + index + '.price'"
          :rules="{required: true, message: 'description is required', trigger: 'blur'}">
            <el-input v-model="item.price"></el-input>
        </el-form-item> -->
        
      </div>
      <el-form-item>
        <el-button type="success" @click="submitForm('form')">Guardar</el-button>
        <el-button type="info" @click="calcelForm('form')">Cancelar</el-button>
      </el-form-item>  
    </el-form>
  </div>
</template>

<script>
import { EventBus } from '../../../../../helpers/EventBus.js'

export default {
  name:"component-catalogo-form",
  data: function () {
    return {
      form: {
          items: [/* {
            field:'',
            fieldValue: '',
            fieldType:'',
            fieldValidation:{},
            isForeignKey:false,
            relation:{
              DataTextListField:'',
              DataValueField:'',
              DataTextField:'',
              Data :[]
            },
            relationInformation:{}
          } */]
      },
      rules: {
        /* username: [
          {
            required: true,
            pattern:/^([0-9A-Za-z]){20}$/,
            message: "Por favor inserta el usuario ",
            trigger: "blur",
          },
        ],
        fkidestatus: [
          {
            required: true,
            message: "Por favor Seleccione un estatus",
            trigger: "blur",
          },
        ], */
      },
      loading: false,
      messageOK: "",
    };
  },

  watch: {

    columns (val) {
      this.loadInfo();
    },

    record (val) {
      /* console.log('xxxxxx');
      if((typeof val === 'undefined')){
        console.log('BBBBB');
        this.resetForm('form');
      } */
      //if(!(typeof val === 'undefined')){
        //console.log('CCCCC');
        //this.resetForm('form');
       
        this.loadInfo();
      //}
    },

    operation (val) {
      if(val == 1){
        //solo para la opcion de agregar.
        this.loadInfo();
      }
    }
  },

  computed: {

    catalog(){
      return  this.$store.getters['jlcrud/catalog'] 
    },

    columns(){
      return  this.$store.getters['jlcrud/columns'] 
    },

    record(){
      return  this.$store.getters['jlcrud/record'];
    },

    operation(){
      return  this.$store.getters['jlcrud/operation'];
    }
  },

  methods: {
    
    isNew(){
      return (typeof this.record === 'undefined')
    },

    generateColumnName(column){
      console.log(column);
      return  (this.columns[column].Caption != "" ) ? this.columns[column].Caption : this.columns[column].ColumnName;
    },

    generateForm(){

      for( let property in this.columns ){
          let model = this.columns[property],prop;

          let obj ={
            field:property,
            fieldValue: "",
            fieldType:model.ColumnType,
            fieldValidation:{},
            isForeignKey:false,
            relation:{},
            relationInformation:{}
          } ;

          if (model.IsForeignKey) {
            obj.isForeignKey = true;
            obj.relation = model.Relation;
            obj.relationInformation = model.ReferenceInformation;
          }

          if(this.isNew() && model.IsInsert){
            //if (model.IsInsert) {
              obj.fieldValue = "";
              this.form.items.push(obj);  
            //}
            
          }else if(!this.isNew() && model.IsUpdate){
            let val = this.record[property];
            if(obj.fieldType == 0 && val == 0){
              val = ""
            }

            obj.fieldValue = val.toString();
            this.form.items.push(obj);  
          }
          
          //this.form.items.push(obj);    
      }
    },

    submitForm(formName) {
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          
          this.loading = true;

          let formData = {} , value;


          for( let property of this.form.items ){
              value = property.fieldValue;
              switch (property.fieldType) {
                  case 0: //DInteger
                    if(value == ""){
                      value = 0;
                    }
                    value = parseInt(value);
                  break;
                  case 1: //DSmallInt
                  
                  break;
                  case 2: //DDouble
                  
                  break;
                  case 3: //DDate
                  
                  break;
                  case 4: //DTimestamp
                  
                  break;
                  case 5: //DBoolean
                  
                  break;
                  case 6: //DBlob
                  
                  break;
                  case 7: //DText
                  
                  break;
                  case 8: //DInt64
                  
                  break;
                  case 9: //DFloat
                  
                  break;
                  case 10: //DNil
                  
                  break;
                  case 11://DGeometry
                  
                  break;
                  case 12: //DGeography
                  
                  break;
              
                default:
                  break;
              }

              formData[property.field] = value;
          }

          this.$store.dispatch("jlcrud/save", formData)
          .then(function (response) {
              self.$message({
                message: self.messageOK,
                type: "success",
              });
              //self.loadInfo();
              self.loading = false;
              self.resetForm(); //--> change
              EventBus.$emit('catalog-save');
          })
          .catch(function (error) {
              self.loading = false;
              EventBus.$emit('error',error);
          });

        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      this.loading = false;
      this.form.items =[];
    },

    calcelForm(formName) {
      //this.resetForm('form');
      EventBus.$emit('catalog-close');
    },

    loadInfo(){
      this.resetForm('form');
      this.generateForm();
    },
  },

  beforeMount() {
    this.loadInfo();
  }
};
</script>

<style>

</style>