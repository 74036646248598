var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "form",
          staticClass: "demo-form-inline",
          attrs: { model: _vm.form, rules: _vm.rules, inline: true }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Nombre", prop: "nombre" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.nombre,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nombre", $$v)
                  },
                  expression: "form.nombre"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Segundo nombre", prop: "segundoNombre" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.segundoNombre,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "segundoNombre", $$v)
                  },
                  expression: "form.segundoNombre"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "A. Paterno", prop: "paterno" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.paterno,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "paterno", $$v)
                  },
                  expression: "form.paterno"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "A. Materno", prop: "materno" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.materno,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "materno", $$v)
                  },
                  expression: "form.materno"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "CURP", prop: "curp" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.curp,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "curp", $$v)
                  },
                  expression: "form.curp"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "CUIP", prop: "cuip" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.cuip,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "cuip", $$v)
                  },
                  expression: "form.cuip"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm("form")
                    }
                  }
                },
                [_vm._v("Buscar")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.calcelForm("form")
                    }
                  }
                },
                [_vm._v("Cancelar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }