var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("site-header"),
      _vm._v(" "),
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "row margin-bottom-40" }, [
          _c("div", { staticClass: "col-md-12 col-sm-12" }, [
            _c("div", { staticClass: "content-page" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "row margin-bottom-20" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "service-box-v1",
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/Core/admin")
                            }
                          }
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Administración Core")])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "service-box-v1",
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/comun/admin")
                            }
                          }
                        },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Administración Común")])
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row margin-bottom-20" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "service-box-v1",
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/EstadoFuerza/admin")
                            }
                          }
                        },
                        [
                          _vm._m(2),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Administración Estado de Fuerza")])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "service-box-v1",
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/Armeria/admin")
                            }
                          }
                        },
                        [
                          _vm._m(3),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Administración Armeria")])
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row margin-bottom-20" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "service-box-v1",
                          on: {
                            click: function($event) {
                              return _vm.$router.push(
                                "/DesarrolloPolicial/admin"
                              )
                            }
                          }
                        },
                        [
                          _vm._m(4),
                          _vm._v(" "),
                          _c("h2", [
                            _vm._v("Administración Desarrollo policial")
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "service-box-v1",
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/Equipamiento/admin")
                            }
                          }
                        },
                        [
                          _vm._m(5),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Administración Equipamiento")])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "service-box-v1",
                          on: {
                            click: function($event) {
                              return _vm.$router.push(
                                "/Profesionalizacion/admin"
                              )
                            }
                          }
                        },
                        [
                          _vm._m(6),
                          _vm._v(" "),
                          _c("h2", [
                            _vm._v("Administración Profesionalización")
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "service-box-v1",
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/Alertamiento/admin")
                            }
                          }
                        },
                        [
                          _vm._m(7),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Administración Alertamiento")])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        {
                          staticClass: "service-box-v1",
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/Canino/admin")
                            }
                          }
                        },
                        [
                          _vm._m(8),
                          _vm._v(" "),
                          _c("h2", [_vm._v("Administración Caninos")])
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "fa fa-cog color-grey" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "fa fa-globe color-grey" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "fa fa-user-o color-grey" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "fa fa-bomb color-grey" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "fa fa-certificate color-grey" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "fa fa-cog color-grey" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-graduation-cap color-grey" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-exclamation-triangle color-grey" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "fa fa-paw color-grey" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }