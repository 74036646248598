import GLOBALS from "../app/globals.ts";
import { Utils } from './utils-service.js';

export const AppDataProvider = {

    getCountRecords: function(table, TOKEN) {
        if (table == "" || typeof(table) === 'undefined') {
            throw 'nombre del catalogo no valido.'
        }

        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/Administracion/count/" + table,
            TOKEN
        );
    },

    searchPersonal: function(formData, TOKEN) {
        /* http://127.0.0.1:8080/v2017001/API/EonCloud/EstadoFuerza/search/personal
          {  
            "nombre":"PABLO",
            "segundoNombre":"",
            "apaterno":"",
            "amaterno":"",
            "curp":"AAMO540520HGRDRR09",
            "CUIP":""
          } */
        return Utils.httpPost(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/search/personal",
            formData,
            TOKEN
        );
    },

    getPersonal: function(idPersonal, TOKEN, pageNumber, rowsOfPage) {
        //console.log("I am here! " + pageNumber + "-" + rowsOfPage);
        if (idPersonal == 0 || typeof(idPersonal) === 'undefined') {
            if (!(typeof(pageNumber) === 'undefined' && typeof(rowsOfPage) === 'undefined')) {
                return Utils.httpGet(
                    GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/page/" + pageNumber + "/rowsOfPage/" + rowsOfPage,
                    TOKEN
                );
            } else {
                return Utils.httpGet(
                    GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal",
                    TOKEN
                );
            }
        } else {
            return Utils.httpGet(
                GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + idPersonal,
                TOKEN
            );
        }
    },

    /* catalogos */
    getEstatusUsuario: function(idEstatus, TOKEN) {
        if (idEstatus == 0 || typeof(idEstatus) === 'undefined') {
            return Utils.httpGet(
                GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/estatusPersonal/",
                TOKEN
            );

        } else {

            return Utils.httpGet(
                GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/estatusPersonal/" + idEstatus,
                TOKEN
            );
        }
    },

    getPerfiles: function(idPerfil, TOKEN) {
        if (idPerfil == 0 || typeof(idPerfil) === 'undefined') {
            return Utils.httpGet(
                GLOBALS.API_URL + "PoliciaCuadrantes/account/perfiles/",
                TOKEN
            );

        } else {

            return Utils.httpGet(
                GLOBALS.API_URL + "PoliciaCuadrantes/account/perfiles/" + idPerfil,
                TOKEN
            );
        }
    },

    /* getCandidato: function(idPersonal) {
        console.log("I am here!! : " + GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/candidato/" + idPersonal);
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/candidato/" + idPersonal,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getCandidatos: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/candidato",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getPreAspitantes: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/preAspirante/",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getPreAspitante: function(idPersonal) {

        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/preAspirante/" + idPersonal,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getAspirantes: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/pendientesBiometricos/",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getPersonales: function(idPersonal) {
        console.log(GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/");
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getPersonal: function(idPersonal) {
        console.log(GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + idPersonal);
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + idPersonal,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getEstudiosPersonal: function(idPersonal) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + idPersonal + "/estudios",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getDocumentosPersonal: function(idPersonal) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + idPersonal + "/documento",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getBiometricosPersonal: function(idPersonal) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + idPersonal + "/biometricos",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getEvaluacionMedicaPersonal: function(idPersonal) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + idPersonal + "/evaluacionMedica",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getDictamenPersonal: function(idPersonal) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + idPersonal + "/dictamen",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    }, */

    /*************** Catalogos ******************/


    /* getTipoNacionalidad: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/tipoNacionalidad/",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getEstadoCivil: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/estadoCivil/",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getEstados: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/estados/",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getMunicipios: function(idEstado) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/estados/" + idEstado + "/municipios",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getEstudios: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/estudios/",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getEstatusEstudios: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/estatusEstudios/",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getComprobanteEstudios: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/comprobanteEstudios/",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getTipoDocumento: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/tipoDocumento/",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },
    getTipoBiometricos: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/tipoBiometrico/",
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );

    },

    getTipoInstancias: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/instancias" + (typeof ID === 'undefined') ? "" : "/" + ID,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getPuestos: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/dependencias" + (typeof ID === 'undefined') ? "" : "/" + ID,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getTipoRegiones: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/dependencias" + (typeof ID === 'undefined') ? "" : "/" + ID,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getTipoSede: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/dependencias" + (typeof ID === 'undefined') ? "" : "/" + ID,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getDependencias: function(ID) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/dependencias" + (typeof ID === 'undefined') ? "" : "/" + ID,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getPuestosDependencia: function(ID) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/dependencias" + (typeof ID === 'undefined') ? "" : "/" + ID,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getRamasDependencia: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/dependencias" + (typeof ID === 'undefined') ? "" : "/" + ID,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getRegiones: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/dependencias" + (typeof ID === 'undefined') ? "" : "/" + ID,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },


    getSedes: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/dependencias" + (typeof ID === 'undefined') ? "" : "/" + ID,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    },

    getSubSedes: function() {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/dependencias" + (typeof ID === 'undefined') ? "" : "/" + ID,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    }, */
}