<template>
   <div class="row">
      <div class="col-md-12">
         <div class="portlet box blue">
            <div class="portlet-title">
               <div class="caption">
                  <i class="fa fa-edit"></i>Personal
               </div>
               <div class="tools">
                  <!-- <a href="javascript:;" class="collapse">
                     </a>
                     <a href="#portlet-config" data-toggle="modal" class="config">
                     </a>
                     <a href="javascript:;" class="reload">
                     </a>
                     <a href="javascript:;" class="remove">
                     </a> -->
               </div>
            </div>
            <div class="portlet-body">
               <div class="table-toolbar">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="btn-group">
                           <!-- <el-button type="primary" icon="el-icon-plus"  circle @click="dialogFormPersonalVisible = true" > Agregar </el-button> -->
                        </div>
                        <el-dialog title="Personal" @close="closeModal" :visible.sync="dialogFormPersonalVisible">
                           <component-personal-profile></component-personal-profile>
                        </el-dialog>

                        <component-personal-search-full @search-personal="handleSerchPersonal" @cancel-search-personal="handleCancelSerchPersonal"></component-personal-search-full>
                        
                     </div>
                     <!-- <div class="col-md-6">
                        <div class="btn-group pull-right">
                           <button class="btn dropdown-toggle" data-toggle="dropdown">Tools <i class="fa fa-angle-down"></i>
                           </button>
                           <ul class="dropdown-menu pull-right">
                              <li>
                                 <a href="#">
                                 Print </a>
                              </li>
                              <li>
                                 <a href="#">
                                 Save as PDF </a>
                              </li>
                              <li>
                                 <a href="#">
                                 Export to Excel </a>
                              </li>
                           </ul>
                        </div>
                     </div> -->
                  </div>
               </div>
               <div id="sample_editable_1_wrapper" class="dataTables_wrapper no-footer">
                  <div class="row">
                     <div class="col-md-6 col-sm-12">
                        <!-- <div class="dataTables_length" id="sample_editable_1_length">
                           <label>
                               <div class="select2-container form-control input-xsmall input-inline" id="s2id_autogen1">
                                 <a href="javascript:void(0)" class="select2-choice" tabindex="-1"> 
                                   <span class="select2-chosen" id="select2-chosen-2">&nbsp;</span>
                                   <abbr class="select2-search-choice-close"></abbr>   
                                   <span class="select2-arrow" role="presentation">
                                     <b role="presentation"></b>
                                   </span>
                                 </a>
                                 <label for="s2id_autogen2" class="select2-offscreen"></label>
                                 <input class="select2-focusser select2-offscreen" type="text" aria-haspopup="true" role="button" aria-labelledby="select2-chosen-2" id="s2id_autogen2">
                                 <div class="select2-drop select2-display-none select2-with-searchbox">   
                                     <div class="select2-search">
                                       <label for="s2id_autogen2_search" class="select2-offscreen"></label>
                                       <input type="text" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" class="select2-input" role="combobox" aria-expanded="true" aria-autocomplete="list" aria-owns="select2-results-2" id="s2id_autogen2_search" placeholder="">
                                     </div>
                                     <ul class="select2-results" role="listbox" id="select2-results-2">  
                                     </ul>
                                 </div>
                               </div>
                               <select name="sample_editable_1_length" aria-controls="sample_editable_1" class="form-control input-xsmall input-inline select2-offscreen" tabindex="-1" title="">
                                 <option value="5">5</option>
                                 <option value="15">15</option>
                                 <option value="20">20</option>
                                 <option value="-1">All</option>
                               </select>
                               records
                           </label>
                           </div> -->
                     </div>
                     <div class="col-md-6 col-sm-12">
                        <!-- <div id="sample_editable_1_filter" class="dataTables_filter"><label>Search:<input type="search" class="form-control input-small input-inline" aria-controls="sample_editable_1"></label></div> -->
                     </div>
                  </div>
                  <div class="table-scrollable">
                     <!-- :data="datos.filter(data => !search || datos.Discapacidad.toLowerCase().includes(search.toLowerCase()))" -->
                     <!-- :data="datos" -->
                     <el-table 
                        :data="filterData"
                        :cell-style="{padding: '0', height: '35px'}"
                        :header-row-style="{color: '#990000'}"
                        class=" table-striped table-hover table-bordered dataTable no-footer"
                        v-loading="loadingTablePersonal"
                        stripe>
                        <el-table-column label="ID"
                           prop="idPersonal">
                        </el-table-column>
                        <el-table-column label="CUIP"
                           prop="CUIP">
                        </el-table-column>
                        <el-table-column label="Nombre"
                           prop="nombre">
                        </el-table-column>
                        <el-table-column label="A. Paterno"
                           prop="apaterno">
                        </el-table-column>
                        <el-table-column label="A. Materno"
                           prop="amaterno">
                        </el-table-column>
                        
                        <el-table-column label="Operaciones" align="right">
                           <template slot="header" slot-scope="">
                           <el-input
                              v-model="search"
                              size="mini"
                              placeholder="Type to search"/>
                           </template>
                           <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" content="Actualizar Pre-Aspirante" placement="bottom">
                                 <el-button type="primary" @click.prevent="handleUpdatePersonal(scope.$index, scope.row)" icon="el-icon-edit-outline" size="mini"></el-button>
                              </el-tooltip>
                              <!-- <el-tooltip class="item" effect="dark" content="Eliminar Pre-Aspirante" placement="bottom">
                                 <el-button type="danger" @click.prevent="handleDeletePersonal(scope.$index, scope.row)" icon="el-icon-delete" size="mini"></el-button>
                              </el-tooltip> -->
                           </template>
                        </el-table-column>
                     </el-table>
                  </div>
                  <div class="row">
                     <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-sizes="[50, 100, 200, 300]"
                        :page-size="pagesize"
                        layout="sizes, prev, pager, next"
                        :total="totalRecords">
                     </el-pagination>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
  import { EventBus } from '../../../../helpers/EventBus.js';
  import PersonalProfileFull from './personal-profile-full/personal-profile-full.vue';
  import SearchPersonalFull from './personal-search-full/personal-search-full.vue'

  export default {
   name: "component-admin-personal",
   components: {
      'component-personal-profile': PersonalProfileFull,
      'component-personal-search-full': SearchPersonalFull,
   },
  
   data() {
      return {
        totalRecords : 0,
        pagesize:100,
        currentPage : 1,
        dialogFormPersonalVisible: false,
        search: "",
        loadingTablePersonal: false,
        messageOK: "",
      }
   },
   computed: {

      personal(){
         this.$store.getters['person/currentPersonal']
      },

      datos() {
         return this.$store.getters['list/personal'];
      },

      filterData() {

            return this.datos.filter(data => !this.search || data.nombre.toLowerCase().includes(this.search.toLowerCase()) || data.CUIP.toLowerCase().includes(this.search.toLowerCase()))
      }
   },
   methods: {
      resetForm() {
         this.loading = false;
      },

      loadInfo(){
         //this.getPersonal();
         let self = this;
         this.loadingTablePersonal = true;
         this.$store.dispatch("list/getPersonalRoundCount","core.personal")
         .then(function (result) {
            self.totalRecords = result[0].totalRecors;
            self.$store.dispatch("list/getPersonal",{
               pageNumber:self.currentPage,
               rowsOfPage:self.pagesize
            })
            .then(function (res) {
               self.loadingTablePersonal = false;
               
            })
            .catch(function (error) {
               self.loadingTablePersonal = false;
               EventBus.$emit('error',error);
            });
            
         })
         .catch(function (error) {
            self.loadingTablePersonal = false;
            EventBus.$emit('error',error);
         });

      }, 

      getPersonal() {
         let self = this;
         this.loadingTablePersonal = true;
         this.$store.dispatch("list/getPersonal")
         .then(function (res) {
            self.loadingTablePersonal = false;
            /* if (self.personal != undefined) {
               self.personal = self.datos.find(element => element.idPersonal == self.personal.idPersonal);
            } */
         })
         .catch(function (error) {
            self.loadingTablePersonal = false;
            EventBus.$emit('error',error);
         });
      },

      closeModal() {
         this.$store.dispatch("person/deleteCurrentPersonal");
         this.dialogFormPersonalVisible = false;
      },

      handleUpdatePersonal(index, row) {
         let record = this.datos.find(element => element.idPersonal == row.idPersonal);
         this.$store.dispatch("person/setCurrentPersonal", record);
         this.dialogFormPersonalVisible = true;
      },

      handleDeletePersonal(index, row) {

      },

      handleSizeChange(val) {
        this.pagesize = val;
        this.currentPage = 1;
        this.loadInfo();
        
      },

      handleCurrentChange(val) {
        this.currentPage = val;
        this.loadInfo();
      },

      handleSerchPersonal(formData){
         let self = this;
         this.loadingTablePersonal = true;
         this.$store.dispatch("list/searchPersonal" , formData)
         .then(function (res) {
            //console.log(JSON.stringify(res));
            self.loadingTablePersonal = false;
         })
         .catch(function (error) {
            self.loadingTablePersonal = false;
            EventBus.$emit('error',error);
         });
      
      },

      handleCancelSerchPersonal(){
         this.loadInfo();
      }
   },

   created() {
      /*self = this;
       EventBus
      .$on('personal-save', () => {
         //this.closeModal();
         this.loadInfo();
      }).$on('personal-close', () => {
         this.closeModal();
      }); */
   },

   beforeMount() {
      this.loadInfo();
   }

  };
</script>
<style lang="css">
  @import url("../../../../assets/global/plugins/datatables/plugins/bootstrap/dataTables.bootstrap.css");
</style>