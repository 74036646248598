var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasUser,
              expression: "hasUser"
            }
          ],
          staticClass: "clearfix margin-top-10"
        },
        [
          _c("span", { staticClass: "label label-danger" }, [
            _vm._v(" NOTE! ")
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("  No cuanta con usuario. ")])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID", prop: "id_usuario" } },
            [
              _c("el-input", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.form.id_usuario,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "id_usuario", $$v)
                  },
                  expression: "form.id_usuario"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Usuario", prop: "username" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.username,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "username", $$v)
                  },
                  expression: "form.username"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Estatus", prop: "fkidestatus" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "w-100",
                  attrs: { placeholder: "Seleccione" },
                  model: {
                    value: _vm.form.fkidestatus,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "fkidestatus", $$v)
                    },
                    expression: "form.fkidestatus"
                  }
                },
                _vm._l(_vm.estatus, function(e) {
                  return _c("el-option", {
                    key: e.id,
                    attrs: { label: e.title, value: e.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm("form")
                    }
                  }
                },
                [_vm._v("Guardar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }