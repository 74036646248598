

<template>
   <div>
      <div class="clearfix margin-top-10" v-show="hasUser">
         <span class="label label-danger"> NOTE! </span>
         <span>&nbsp;&nbsp;No cuanta con usuario. </span>
      </div>
      <!-- :filter-method="filterMethod" -->
      <!-- :right-default-checked="perfilesUsuario" -->
      <div>
         <el-transfer
            filterable
            filter-placeholder="Buscar"
            v-model="perfilesUsuario"
            :data="data"
            @change="handleChange"
            :titles="['Perfiles', 'Perfiles Asignados']"
            >
         </el-transfer>
      </div>
   </div>
</template>



<script>
import { EventBus } from '../../../../../../helpers/EventBus.js'
import {AppDataProvider} from '../../../../../../providers/app-data-provider.js';
import {PersonalService} from '../../../../../../providers/personal-service.js';
import {UserService} from '../../../../../../providers/user-service.js';

export default {
  name:"component-user-profile",
  data: function () {
    return {
      perfilesUsuario: [],
      loading: false,
      messageOK: "",
    };
  },

  watch: {
    personal (val) {
      if(!(typeof val === 'undefined')){
        this.loadInfo();
      }
    }
  },

  computed: {
    personal(){
        return this.$store.getters['person/currentPersonal'];
    },
    user(){
        return this.$store.getters['person/currentUser'];
    },
    hasUser (){
       return (typeof this.user === 'undefined');
    },

    data(){
      let data = this.$store.getters['list/perfiles']
      let datos =[];
      data.forEach((perfil, index) => {
          datos.push({
            label: perfil.catPerfil.title +" - "+ perfil.Applications.title,
            key: perfil.id,
            initial: index
          });
        });
        return datos;
    }
  },

  methods: {

    resetForm(formName) {
      this.loading = false;
      this.perfilesUsuario = [];
    },

    calcelForm(formName) {
      this.resetForm('form');
    },

    loadInfo(){
      this.resetForm('form');
      this.getPerfiles();
      this.getUser();
    },

    getPerfiles() {
      let self = this;
      this.loading = true;
      this.$store.dispatch("list/getPerfiles")
      .then(function (response) {
          //self.loading = false;
      })
      .catch(function (error) {
        self.loading = false;
        EventBus.$emit('error',error);
          
      });
    },

    getUser() {
      let self = this;
      if(typeof this.personal === 'undefined'){
        return;
      }
      this.loading = true;
      this.$store.dispatch("person/getUserPersonal", this.personal.idPersonal)
      .then(function (response) {
          //self.user = response.find(element => element.fk_idPersonal == self.personal.idPersonal);
          let indices = [];
          self.$store.dispatch("person/getProfilesUser",self.user.id)
          .then(function (result){

              if(!(typeof result === 'undefined')){
                result.forEach((perfil, index) => {
                  indices.push(perfil.Perfil.id);
                });
                self.perfilesUsuario = indices
                self.loading = false;
              }
          })
          .catch(function (error) {
            self.loading = false;
            EventBus.$emit('error',error);
          });
      })
      .catch(function (error) {
        self.loading = false;
        //EventBus.$emit('error',error);
          
      });
    },

    getPerfilesUser() {
      let self = this;
      this.loading = true;
      this.$store.dispatch("person/getProfilesUser",this.user.id)
      .then(function (response) {
          self.loading = false;
      })
      .catch(function (error) {
        self.loading = false;
        EventBus.$emit('error',error);
          
      });
    },

    handleChange(value, direction, movedKeys) {
      console.log(value, direction, movedKeys);
      let formData = {
            fk_idPerfil :movedKeys[0],
          };
      
      
      if (direction === 'left') {
          this.$store.dispatch("person/deleteProfile",formData)
          .then(function (response) {
              /* self.$message({
                message: self.messageOK,
                type: "success",
              }); */
              //self.loadInfo();
              self.loading = false;
            })
            .catch(function (error) {
              self.loading = false;
              EventBus.$emit('error',error);
            });
      } else if(direction === 'right') {
        this.$store.dispatch("person/addProfile",formData)
          .then(function (response) {
              self.$message({
                message: self.messageOK,
                type: "success",
              });
              //self.loadInfo();
              self.loading = false;
            })
            .catch(function (error) {
              self.loading = false;
              EventBus.$emit('error',error);
            });
      }else{
        throw('UnsoporterOrationSupported');
      } 
    }
  },

  beforeMount() {
    this.loadInfo();
  }
};
</script>

<style>

</style>