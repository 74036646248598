var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pre-footer" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4 col-sm-6 pre-footer-col" }, [
            _c("h2", [_vm._v("Sobre nosotros")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam\n          sit nonummy nibh euismod tincidunt ut laoreet dolore magna aliquarm\n          erat sit volutpat.\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 col-sm-6 pre-footer-col" }, [
            _c("h2", [_vm._v("Nuestro Contacto")]),
            _vm._v(" "),
            _c("address", { staticClass: "margin-bottom-40" }, [
              _vm._v("\n          35, Lorem Lis Street, Park Ave"),
              _c("br"),
              _vm._v("\n          California, US"),
              _c("br"),
              _vm._v("\n          Phone: 300 323 3456"),
              _c("br"),
              _vm._v("\n          Fax: 300 323 1456"),
              _c("br"),
              _vm._v("\n          Email:\n          "),
              _c("a", { attrs: { href: "mailto:info@eoninnovaction.mx" } }, [
                _vm._v("info@eoninnovaction.mx")
              ]),
              _c("br"),
              _vm._v("\n          Skype: "),
              _c("a", { attrs: { href: "skype:eoninnovaction" } }, [
                _vm._v("Eon Innovaction")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 col-sm-6 pre-footer-col" }, [
            _c(
              "div",
              {
                staticClass:
                  "pre-footer-subscribe-box pre-footer-subscribe-box-vertical"
              },
              [
                _c("h2", [_vm._v("Boletin informativo")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n            Suscríbase a nuestro boletín de noticias y manténgase actualizado\n            con las últimas noticias y ofertas.\n          "
                  )
                ]),
                _vm._v(" "),
                _c("form", { attrs: { action: "#" } }, [
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "youremail@mail.com" }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-group-btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [
                          _vm._v(
                            "\n                  Subscribe\n                "
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }