<template>
  <div>
    <div class="clearfix margin-top-10" v-show="hasUser">
			<span class="label label-danger"> NOTE! </span>
			<span>&nbsp;&nbsp;No cuanta con usuario. </span>
		</div>
  
   <el-form :model="form" :rules="rules" ref="form" v-loading="loading" ><!-- v-show="!hasUser" -->
      <el-form-item label="Contraseña" prop="password">
         <el-input v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item label="Confirmar Contraseña" prop="password">
         <el-input v-model="form.retrypassword"></el-input>
      </el-form-item>
      <el-form-item>
         <el-button type="primary" @click="submitForm('form')" :disabled="comfirm" :loading="loadingBTN">Guardar</el-button>
      </el-form-item>
   </el-form>
  </div>
</template>

<script>
import { EventBus } from '../../../../../../helpers/EventBus.js'
import {PersonalService} from '../../../../../../providers/personal-service.js';
import {UserService} from '../../../../../../providers/user-service.js';
let sha1 = require('sha1');

export default {
  name:"component-user-password",
  data: function () {
    return {
      form: {
        password:"",
        retrypassword: ""
      },
      rules: {
        password: [
          {
            required: true,
            message: "Por favor inserte una contraseña.",
            trigger: "blur",
          },
        ],
        retrypassword: [
          {
            required: true,
            message: "Por favor confirme la contraseña.",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      loadingBTN: false,
      messageOK: "",
    };
  },

  watch: {
    personal (val) {
      this.resetForm('form');
      if(!(typeof val === 'undefined')){
        this.loadInfo();
      }
    }
  },

  computed:{
    comfirm(){
    
        return ((this.form.password === this.form.retrypassword) && (this.form.password != "" && this.form.retrypassword != ""  )) ? false : true;
    },

    personal(){
        return this.$store.getters['person/currentPersonal'];
    },

    user(){
        return this.$store.getters['person/currentUser'];
    },

    hasUser (){
       return (typeof this.user === 'undefined');
    }

  },

  methods: {
    submitForm(formName) {
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadingBTN = true;

          let formData = {
            /* id_usuario : this.user.id, */
            newPassword:sha1(this.form.password).toUpperCase()
          };

          this.$store.dispatch("person/setPasswordUserPersonal", formData)
          .then(function (response) {
              self.$message({
                message: self.messageOK,
                type: "success",
              });
              self.loadInfo();
              self.loadingBTN = false;
            })
            .catch(function (error) {
              self.loadingBTN = false;
              EventBus.$emit('error',error);
            });

        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      this.loading = false;
      this.form.password = "";
      this.form.retrypassword = "";
    },

    calcelForm(formName) {
      this.resetForm('form');
    },


    loadInfo(){
      this.resetForm('form');
      this.getUser();
    },

    getUser() {
      let self = this;
      if(typeof this.personal === 'undefined'){
        return;
      }
      //this.loading = true;
      this.$store.dispatch("person/getUserPersonal", this.personal.idPersonal)
      .then(function (response) {
          //self.user = response.find(element => element.fk_idPersonal == self.personal.idPersonal);
          self.loading = false;
      })
      .catch(function (error) {
        self.loading = false;
        EventBus.$emit('error',error);
          
      });
    },

  },
  beforeMount() {
    this.loadInfo();
  }
};
</script>

<style>

</style>