<template>
  <div>
   <el-form :model="form" :rules="rules" ref="form" v-loading="loading" :inline="true"  class="demo-form-inline">
      <el-form-item label="Nombre" prop="nombre">
         <el-input v-model="form.nombre"></el-input>
      </el-form-item>
      <el-form-item label="Segundo nombre" prop="segundoNombre">
         <el-input v-model="form.segundoNombre"></el-input>
      </el-form-item>
      <el-form-item label="A. Paterno" prop="paterno">
         <el-input v-model="form.paterno"></el-input>
      </el-form-item>
      <el-form-item label="A. Materno" prop="materno">
         <el-input v-model="form.materno"></el-input>
      </el-form-item>
      <el-form-item label="CURP" prop="curp">
         <el-input v-model="form.curp"></el-input>
      </el-form-item>
      <el-form-item label="CUIP" prop="cuip">
         <el-input v-model="form.cuip"></el-input>
      </el-form-item>
      <el-form-item>
         <el-button type="primary" @click.prevent="submitForm('form')">Buscar</el-button>
          <el-button type="primary" @click.prevent="calcelForm('form')">Cancelar</el-button>
      </el-form-item>
   </el-form>
  </div>
</template>

<script>
import { EventBus } from '../../../../../helpers/EventBus.js'
import {AppDataProvider} from '../../../../../providers/app-data-provider.js';
import {PersonalService} from '../../../../../providers/personal-service.js';
import {UserService} from '../../../../../providers/user-service.js';

export default {
  name:"component-personal-search-full",
  data: function () {
    return {
      form: {
        nombre:"",
        segundoNombre: "",
        materno: "",
        paterno: "",
        curp: "",
        cuip: ""
      },
      rules: {
        nombre: [
          {
            required: false,
            pattern:/([0-9A-Za-z])/,
            message: "Por favor inserta el Nombre",
            trigger: "blur",
          },
        ],
        segundoNombre: [
          {
            required: false,
            pattern:/([0-9A-Za-z])/,
            message: "Por favor inserta el Segundo Nombre",
            trigger: "blur",
          },
        ],
        paterno: [
          {
            required: false,
            pattern:/([0-9A-Za-z])/,
            message: "Por favor inserta el Apellido Paterno",
            trigger: "blur",
          },
        ],
        materno: [
          {
            required: false,
            pattern:/([0-9A-Za-z])/,
            message: "Por favor inserta el Apellido Materno",
            trigger: "blur",
          },
        ],
        curp: [
          {
            required: false,
            pattern:/([0-9A-Za-z])/,
            message: "Por favor inserta el CURP",
            trigger: "blur",
          },
        ],
        cuip: [
          {
            required: false,
            pattern:/([0-9A-Za-z])/,
            message: "Por favor inserta el CUIP",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      messageOK: "",
    };
  },

  watch: {
    
  },

  computed: {
    
  },

  methods: {
    submitForm(formName) {
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {

          let formData = {
            nombre: this.form.nombre,
            segundoNombre: this.form.segundoNombre,
            apaterno: this.form.paterno,
            amaterno: this.form.materno,
            curp: this.form.curp,
            CUIP: this.form.cuip,
          };

          this.$emit('search-personal', formData);

        } else {
          return false;
        }
      });
    },

    /* OK */
    resetForm(formName) {
      this.loading = false;
      this.form.nombre ="";
      this.form.segundoNombre = "";
      this.form.paterno = "";
      this.form.materno = "";
      this.form.curp = "";
      this.form.cuip = "";
    },

    calcelForm(formName) {
      this.resetForm('form');
      this.$emit('cancel-search-personal');
    },

    loadInfo(){
      this.resetForm('form');
    },

  },

  beforeMount() {
    this.loadInfo();
  }
};
</script>

<style>

</style>