<template>
  
    <el-form ref="form" role="form" :model="form" :rules="rules" class="login-form">
       <h3>Olvidaste tu contraseña?</h3>
        <p>Ingrese su dirección de correo electrónico a continuación para restablecer su contraseña</p>

        <div class="form-body">
          <el-form-item label="" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
        </div>
        <hr>
        <div class="form-actions">
          <!-- <label class="checkbox">
            <input type="checkbox" name="remember" value="1" /> Remember me
          </label> -->
          <el-form-item>
              <el-button type="primary" @click="submitForm('form')" :loading="loadingButton">Login</el-button>
              <el-button @click="resetForm('form')" >Cancelar</el-button>
          </el-form-item>
        </div>
        
    </el-form>
  
</template>
<script>
  import moment from "moment";
  const axios = require("axios").default;
  import GLOBALS from "../../../../app/globals.ts";

  export default {
    name: "page-forget-password",
    data: function () {
      return {
        form: {
          email: "",
        },
        rules: {
          email: [
            {
              required: true,
              message: "Por favor inserta el correo",
              trigger: "blur",
            },
          ],
        },
        datos: [],
        loadingButton: false,
        messageOK: "",
      };
    },
    methods: {
      /* submit son formularios para enviar a método */
      submitForm(formName) {
        let self = this;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingButton = true;

            /* let formData = {
              nombre: this.form.Nombre,
              segundoNombre: this.form.SegundoNombre,
              apaterno: this.form.ApellidoPaterno,
              amaterno: this.form.ApellidoMaterno,
              fechaNacimiento: moment(this.form.FechaDeNacimiento).format(
                "DD/MM/YYYY"
              ),
              sexo: this.form.Sexo,
              Contacto: {
                telefonoCelular: this.form.Telefono,
                correoElectronico: this.form.Correo,
              },
            };

            let url =
              GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/candidato";
            this.messageOK = "Se creó el pre-registro";

            axios
              .post(url, formData, {})
              .then(function (response) {
                self.$message({
                  message: self.messageOK,
                  type: "success",
                });
                self.loadingInformacionPersonal = false;
                self.loadingButton = false;
              })
              .catch(function (error) {
                console.log(error);
              }); */
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.loadingButton = false;
      },
    },
    beforeMount() {},
  };
</script>
<style lang="css">
  /* @import url("./404.css"); */
</style>