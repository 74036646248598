import { UserService } from '../../providers/user-service.js';
export const userAcount = {
    namespaced: true,
    state: {
        User: {
            token: ' ',
        },
    },
    getters: {
        getCurrentUser: state => state.User,
        ACCESS_TOKEN: state => state.User.token
    },
    mutations: {

        SET_USER: (state, payload) => {
            if (payload.id == 0 || typeof(payload.id) === 'undefined') {
                throw "El usuario no es valido";
            }
            state.User = payload
        },
        DELETE_USER: (state, payload) => {
            if (!(state.User == null || typeof(state.User) === 'undefined')) {
                state.User = undefined;
            }
        },
    },
    actions: {
        doLogin: (context, payload) => {
            return new Promise((resolve, reject) => {

                if ((payload.usuario == "" || typeof(payload.usuario) === 'undefined') || (payload.password == "" || typeof(payload.password) === 'undefined')) {
                    throw "Los datos para el logueo no fueron proporcionados";
                }

                UserService.login(
                    payload.usuario,
                    payload.password
                ).
                then(function(User) {
                    context.commit('SET_USER', User);
                    resolve(User);
                }).catch(function(error) {
                    reject(error);
                });
            });
        },

        logOut: (context, payload) => {
            return new Promise((resolve, reject) => {
                context.commit('DELETE_USER', payload);
                resolve();
            });
        },
    }
};