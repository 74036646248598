var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("body", { staticClass: "account" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "menu-toggler sidebar-toggler" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [_c("router-view", { attrs: { name: "account-content" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "copyright" }, [
      _vm._v("\n        2021 © Eon Innovaction. Account Template.\n    ")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("a", { attrs: { href: "index.html" } }, [
        _c("img", {
          attrs: {
            src: require("../../assets/eft/layout/img/logos/logonuevo.png"),
            alt: ""
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }