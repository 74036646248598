var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "body",
    { staticClass: "page-header-fixed page-quick-sidebar-over-content" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "clearfix" }),
      _vm._v(" "),
      _c("div", { staticClass: "page-container" }, [
        _c("div", { staticClass: "page-sidebar-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "page-sidebar navbar-collapse collapse",
              staticStyle: {
                height: "900px !important",
                "overflow-y": "scroll !important",
                "overflow-x": "hidden !important"
              }
            },
            [
              _c(
                "ul",
                {
                  staticClass: "page-sidebar-menu",
                  attrs: {
                    "data-auto-scroll": "true",
                    "data-slide-speed": "200"
                  }
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._l(_vm.functionalities, function(functionality) {
                    return _c(
                      "li",
                      { key: functionality.Name },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              "active-class": "active",
                              to: functionality.Name
                            }
                          },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v(_vm._s(functionality.Caption))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "selected" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "arrow open" })
                          ]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-content-wrapper" }, [
          _c(
            "div",
            { staticClass: "page-content" },
            [_c("router-view", { attrs: { name: "admin-content" } })],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm._m(2)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header navbar navbar-fixed-top" }, [
      _c("div", { staticClass: "page-header-inner" }, [
        _c("div", { staticClass: "page-logo" }, [
          _c("h3", [_vm._v("ADMINISTRACIÓN")]),
          _vm._v(" "),
          _c("div", { staticClass: "menu-toggler sidebar-toggler hide" })
        ]),
        _vm._v(" "),
        _c("a", {
          staticClass: "menu-toggler responsive-toggler",
          attrs: {
            href: "javascript:;",
            "data-toggle": "collapse",
            "data-target": ".navbar-collapse"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "sidebar-toggler-wrapper" }, [
      _c("div", { staticClass: "sidebar-toggler" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-footer" }, [
      _c("div", { staticClass: "page-footer-inner" }, [
        _vm._v("2021 © Estado Fuerza by Eon Innovaction.")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "scroll-to-top" }, [
        _c("i", { staticClass: "icon-arrow-up" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }