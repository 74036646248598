var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "container" }, [
        _c("a", { staticClass: "site-logo" }, [
          _c("img", {
            attrs: {
              src: require("../../../../assets/eft/layout/img/logos/logonuevo.png"),
              alt: "Inicio"
            }
          })
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "header-navigation pull-right font-transform-inherit"
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }