<template>
  <body id="app" :loading="loading" @keyup.enter="KeyPressed($event)">
      <router-view name='site'></router-view>
  </body>
</template>
<script>
    import { EventBus } from '../helpers/EventBus.js';
    export default {
        name: "app",
        components: {
        },
        data() {
            return {
                stylecurrentpage:'',
                currentpage:'',
                loading:false
            };
        },
        methods:{
            KeyPressed(e){
                EventBus.$emit('keyPressed-enter');
            }
        },
        created() {
            EventBus
            .$on('loading', () => {
                this.loading = true;
            })
            .$on('endLoading', () => {
                this.loading = false;
            })
            .$on('error', (error) => {
                this.$fire({
                    title:'Error!',
                    text: error,
                    type: 'error',
                    confirmButtonText:'OK'
                });
            });
        },
        
    };

</script>
<style lang="css">
    /* scroll */
    *::-webkit-scrollbar { width: 6px; }
    *::-webkit-scrollbar-track { background-color: #afafaf; }
    *::-webkit-scrollbar-thumb { background-color: #0d1110; }
    .scroll { overflow-x: auto; }
    .scroll::-webkit-scrollbar {height: 5px;}
    .scroll::-webkit-scrollbar-track {background-color: #afafaf;}
    
    
    .scrolly {overflow-y: scroll;}
    .scrolly::-webkit-scrollbar {height: 5px;}
    .scrolly::-webkit-scrollbar-track { background-color: #afafaf; }
    .scrolly::-webkit-scrollbar-thumb { background-color: #0d1110; }
    .tblMaxh {max-height: 200px;    padding-right: 14px;}
    /* @import url("../assets/global/css/components.css");
    @import url("../assets/global/css/plugins.css"); */
</style>

