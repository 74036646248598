var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pre-header" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "wrapper" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6 col-sm-6 additional-nav" }, [
            _c("ul", { staticClass: "list-unstyled list-inline pull-right" }, [
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/account" } }, [
                    _vm._v("Log In")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/registro" } }, [
                    _vm._v("Registration")
                  ])
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-6 col-sm-6 additional-shop-info" },
      [
        _c("ul", { staticClass: "list-unstyled list-inline test" }, [
          _c("li", [
            _c("i", { staticClass: "fa fa-phone" }),
            _c("span", [_vm._v("+55 777 77 77")])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("i", { staticClass: "fa fa-envelope-o" }),
            _c("span", [_vm._v("info@eoninnovaction.mx")])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "col-md-5" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-3  hidden-xs hidden-sm" }),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-2" }),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-10" }, [
              _c(
                "div",
                { staticClass: "search-box hidden-xs hidden-sm clearfix" },
                [
                  _c("form", { attrs: { action: "#" } }, [
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "Buscar" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "input-group-btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "submit" }
                          },
                          [
                            _c("span", {
                              staticClass: "icon glyphicon glyphicon-search",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }