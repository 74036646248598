

export default class GLOBALS {
    static servers = [
        //"http://localhost:8080/v2017001/API/", //<--Development
        //"https://anam.eonproduccion.net:31001/v2017001/API/", //<--Development
        //"http://127.0.0.1:8080/v2017001/API/", //<--Development
        //"https://eonproduccion.net:31001/v2017001/API/", //<--Development
        "https://10.5.8.9:31001/v2017001/API/",//<-- Produccion
        "http://???/", //<--Production
    ];
    static serversFiles = [
        //"http://cesesptlax.eonproduccion.net/CICG_SEG_SCM_WORK/api/", //<--Development
        "http://???/", //<--Production
    ];

    static get API_URL() {
        /* return this.servers[this.DEV_MODE ? 0 : 1] + "PoliciaCuadrantes/"; */
        return this.servers[this.DEV_MODE ? 0 : 1];
    };

    static get API_URL_FILES() {
        /* return this.servers[this.DEV_MODE ? 0 : 1] + "PoliciaCuadrantes/"; */
        return this.serversFiles[this.DEV_MODE ? 0 : 1];
    };

    static get DEBUG_MODE() { return this.DEBUGMODE };
    static get TESTING_MODE() { return this.DEBUGMODE };

    static get LOG_INFO() { return "info" };
    static get LOG_WARN() { return "warn" };
    static get LOG_ERROR() { return "error" };
    static get LOG_LEVEL() { return this.DEBUGMODE ? this.LOG_INFO : this.LOG_WARN }; //{info, warn, error}

    static get ONE_SECOND() { return 1000 };
    static get ONE_MINUTE() { return 60 * this.ONE_SECOND };

    
    static DEV_MODE = true; //true = server EON, false= server  asi como habilitacion/muestra de botones etc
    static DEBUGMODE = true; //true = se escribe en consola y en alertas los detalles de errores, etc.

}