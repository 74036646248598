var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasUser,
            expression: "hasUser"
          }
        ],
        staticClass: "clearfix margin-top-10"
      },
      [
        _c("span", { staticClass: "label label-danger" }, [_vm._v(" NOTE! ")]),
        _vm._v(" "),
        _c("span", [_vm._v("  No cuanta con usuario. ")])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("el-transfer", {
          attrs: {
            filterable: "",
            "filter-placeholder": "Buscar",
            data: _vm.data,
            titles: ["Perfiles", "Perfiles Asignados"]
          },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.perfilesUsuario,
            callback: function($$v) {
              _vm.perfilesUsuario = $$v
            },
            expression: "perfilesUsuario"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }