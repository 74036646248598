import { JlCrudService } from '../../providers/jlCrud-service.js';
import { catalogs } from '../../helpers/catalogs.js';
export const JlCrud = {
    namespaced: true,

    state: {
        catalog: undefined,
        tableName: undefined,
        structure: undefined,
        piPrimaryKey: undefined,
        columns: undefined,
        showsColumns: [],
        record: undefined,
        operation: undefined,
        records: [],

    },

    getters: {
        catalog: state => state.catalog,
        tableName: state => state.tableName,
        structure: state => state.structure,
        piPrimaryKey: state => state.piPrimaryKey,
        columns: state => state.columns,
        showsColumns: state => state.showsColumns,
        record: state => state.record,
        records: state => state.records,
        operation: state => state.operation
    },

    mutations: {

        SET_CATALOG: (state, payload) => {
            state.catalog = payload;
        },

        SET_TABLENAME: (state, payload) => {
            state.tableName = payload;
        },

        SET_STRUCTURE: (state, payload) => {
            state.structure = payload;
        },

        SET_PIPRIMARYKEY: (state, payload) => {
            state.piPrimaryKey = payload;
        },

        SET_COLUMNS: (state, payload) => {
            state.columns = payload;
        },

        SET_SHOWSCOLUMNS: (state, payload) => {
            state.showsColumns = payload;
        },

        SET_CURRENT_OPERATION: (state, payload) => {
            state.operation = payload;
        },

        SET_CURRENT_RECORD: (state, payload) => {
            state.record = payload;
        },

        GET_RECORDS: (state, payload) => {
            state.records = payload;
        },
    },

    actions: {
        setCatalog: (context, payload) => {
            context.commit('SET_SHOWSCOLUMNS', []);
            context.commit('SET_COLUMNS', []);
            context.commit('SET_PIPRIMARYKEY', undefined);
            context.commit('SET_TABLENAME', undefined);
            context.commit('SET_STRUCTURE', undefined);
            context.commit('SET_CURRENT_RECORD', undefined);
            context.commit('SET_CURRENT_OPERATION', undefined);
            context.commit('GET_RECORDS', []);
            context.commit('SET_CATALOG', payload);
        },
        setCurrentOperation: (context, payload) => {
            context.commit('SET_CURRENT_OPERATION', payload);
        },

        setCurrentRecord: (context, payload) => {
            context.commit('SET_CURRENT_RECORD', payload);
        },

        getStructure: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                JlCrudService.getStructure(payload, TOKEN).
                then(function(response) {
                    let structure = response[0];
                    if ((typeof structure === 'undefined') || (typeof structure.DataTable.mColumns.mDictionary === 'undefined')) {
                        reject("El valor retornado por la API , no es lo esperado.");
                    }
                    context.commit('SET_STRUCTURE', structure);
                    resolve(structure);
                }).catch(function(error) {
                    reject(error);
                });
            });
        },

        getAllRecords: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'];
                JlCrudService.get(payload, TOKEN).
                then(function(response) {
                    context.commit('GET_RECORDS', response);
                    resolve(response);
                }).catch(function(error) {
                    reject(error);
                });
            });
        },

        get: (context, payload) => {
            return new Promise((resolve, reject) => {
                if (typeof context.rootGetters['jlcrud/structure'] === 'undefined') {
                    context.dispatch('getStructure', payload)
                        .then(function(res) {

                            console.log(JSON.stringify(res));

                            let showscolumns = [],
                                columns = {},
                                piPrimaryKey,
                                tableName;
                            let relations = res.Relations;
                            let obj = res.DataTable.mColumns.mDictionary;
                            tableName = res.DataTable.TableName;
                            for (let property in obj) {
                                let model = obj[property],
                                    column;

                                if (model.IsPrimaryKey) {
                                    piPrimaryKey = model.ColumnName
                                }

                                column = Object.assign({}, obj[property]);

                                if (model.IsForeignKey && (relations != null && !(typeof relations === 'undefined'))) {
                                    let aro = model.ReferenceInformation.CONSTRAINT_NAME.split('_');
                                    if (relations.hasOwnProperty(model.ColumnName)) {

                                        column['Relation'] = {
                                            DataTextListField: aro[aro.length - 1] + "." + relations[model.ColumnName].DataTextField,
                                            DataValueField: relations[model.ColumnName].DataValueField,
                                            DataTextField: relations[model.ColumnName].DataTextField,
                                            Data: []
                                        }

                                        column['ReferenceInformation'] = model.ReferenceInformation;

                                        //let catalogs = context.rootGetters['jlcrud/catalogs'];
                                        context.dispatch('getAllRecords', catalogs[column['ReferenceInformation'].REFERENCED_TABLE_NAME])
                                            .then(function(res) {
                                                column['Relation'].Data = res
                                            })
                                            .catch(function(error) {
                                                reject(error);
                                            });
                                    }


                                }


                                if (model.IsList) {
                                    if (!model.IsForeignKey) {
                                        showscolumns.push({
                                            label: (model.Caption != "") ? model.Caption : model.ColumnName,
                                            propList: model.ColumnName,
                                            prop: model.ColumnName,
                                            priority: model.Priority
                                        });
                                    } else {

                                        if (model.IsForeignKey && (relations != null && !(typeof relations === 'undefined'))) {
                                            let aro = model.ReferenceInformation.CONSTRAINT_NAME.split('_');
                                            if (relations.hasOwnProperty(model.ColumnName)) {
                                                showscolumns.push({
                                                    label: (model.Caption != "") ? model.Caption : model.ColumnName,
                                                    propList: aro[aro.length - 1] + "." + relations[model.ColumnName].DataTextField,
                                                    prop: model.ColumnName,
                                                    priority: model.Priority
                                                });
                                            }
                                        }

                                    }
                                }

                                columns[property] = column;
                            }

                            console.log(JSON.stringify(showscolumns));
                            console.log(JSON.stringify(columns));
                            console.log(JSON.stringify(piPrimaryKey));
                            console.log(JSON.stringify(tableName));


                            context.commit('SET_SHOWSCOLUMNS', showscolumns.sort((a, b) => a.priority - b.priority));
                            context.commit('SET_COLUMNS', columns);
                            context.commit('SET_PIPRIMARYKEY', piPrimaryKey);
                            context.commit('SET_TABLENAME', tableName);
                            console.log(payload);

                            context.dispatch('getAllRecords', payload)
                                .then(function(res) {
                                    console.log(JSON.stringify(res));
                                    resolve(res);
                                })
                                .catch(function(error) {
                                    reject(error);
                                });
                        })
                        .catch(function(error) {
                            reject(error);
                        });
                } else {
                    context.dispatch('getAllRecords', payload)
                        .then(function(res) {
                            resolve(res);
                        })
                        .catch(function(error) {
                            reject(error);
                        });
                }
            });
        },

        save: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'],
                    record = context.getters.record,
                    catalog = context.getters.catalog,
                    piPrimaryKey = context.getters.piPrimaryKey,
                    operation = context.getters.operation;
                if (typeof record === 'undefined') {
                    record = payload;
                } else {
                    Object.assign(record, payload)
                }

                JlCrudService.save(catalog.name, record, record[piPrimaryKey], operation, TOKEN)
                    .then(function(response) {
                        resolve(response);
                    }).catch(function(error) {
                        reject(error);
                    });
            });
        },

        delete: (context, payload) => {
            return new Promise((resolve, reject) => {
                let TOKEN = context.rootGetters['account/ACCESS_TOKEN'],
                    record = context.getters.record,
                    catalog = context.getters.catalog,
                    piPrimaryKey = context.getters.piPrimaryKey;

                JlCrudService.delete(catalog.name, record[piPrimaryKey], TOKEN)
                    .then(function(response) {
                        resolve(response);
                    }).catch(function(error) {
                        reject(error);
                    });
            });
        },

    }
};