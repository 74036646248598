import GLOBALS from "../app/globals.ts";
import { Utils } from './utils-service.js';

export const PersonalService = {

    getUser: function(idPersonal, Token) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/personal/" + idPersonal + "/usuario",
            Token
        );
    },

    saveUser: function(record, idPersonal, idUser, Token) {
        if ((typeof record === 'undefined') || (typeof idPersonal === 'undefined') || (typeof idPersonal === 'undefined')) {
            return;
        }

        if (idUser == 0 || typeof(idUser) === 'undefined') {
            return Utils.httpPost(
                GLOBALS.API_URL + "PoliciaCuadrantes/personal/" + idPersonal + "/usuario",
                record,
                Token
            );
        } else {
            return Utils.httpUpdate(
                GLOBALS.API_URL + "PoliciaCuadrantes/personal/" + idPersonal + "/usuario/" + idUser,
                record,
                Token
            );
        }
    },

    getProfiles: function(idUser, Token) {
        return Utils.httpGet(
            GLOBALS.API_URL + "PoliciaCuadrantes/account/usuario/" + idUser + "/perfiles",
            Token
        );
    },

    addProfiles: function(record, idUser, Token) {
        return Utils.httpPost(
            GLOBALS.API_URL + "PoliciaCuadrantes/account/usuario/" + idUser + "/perfiles",
            record,
            Token
        );
    },

    deleteProfiles: function(record, idUser, Token) {
        return Utils.httpUpdate(
            GLOBALS.API_URL + "PoliciaCuadrantes/account/usuario/" + idUser + "/perfiles",
            record,
            Token
        );
    },

    save: function(record) {
        if (typeof record === 'undefined') {
            return;
        }

        if (record.idPersonal == 0 || typeof(record.idPersonal) === 'undefined') {
            console.log("ADD : " + JSON.stringify(record));
            return Utils.httpPost(
                GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal",
                record,
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
            );

        } else {
            console.log("ADD : " + JSON.stringify(record));
            return Utils.httpUpdate(
                GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + record.idPersonal,
                record,
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
            );
        }
    },

    /* turn: function(record, idPersonal) {
        if (typeof record === 'undefined') {
            return;
        }

        if (idPersonal != 0 || !(typeof(idPersonal) === 'undefined')) {
            console.log("TURN : " + JSON.stringify(record));
            return Utils.httpPost(
                GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + idPersonal + "/derivacion",
                record,
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
            );

        }
    },

    addPhotograph: function(formData) {
        if (typeof formData === 'undefined') {
            return;
        }

        return Utils.httpPost(
            GLOBALS.API_URL_FILES + "Fotografia/uploadPhotograph",
            formData,
            '',

            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    },


    addCuip: function(record) {
        if (typeof record === 'undefined') {
            return;
        }

        console.log("ADD CUIP: " + JSON.stringify(record));
        return Utils.httpUpdate(
            GLOBALS.API_URL + "PoliciaCuadrantes/EstadoFuerza/personal/" + record.idPersonal + "/cuip",
            record,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNTEzODEwMjc3IiwiaWF0IjoiMTUxMzgxMDI3NyIsImlkUHJvZmlsZSI6MSwianRpIjoiOTZCM0JCRTUtRUYxNS00RjQxLUIzOEItRjJCMUNFRDc0RkZCIiwic2VjcmV0IjoiSkwyU0daQ1BOM0NIRE9NSlpXQkozRjRNT0lWSVI3WFlSWlpQQ0g3QlJORlpBV1FFR0pNN1gzU1VHRDRIV01LS1I1WFVERENNNjNFN1FEVUtES0hRUjczQkRDVzJHU0U1VFdUTEJDWSIsInN1YiI6MSwidmFsaWRpdHkiOjB9.go9Nudv0toho05HhzkLsV6OikO5KmDvZMrFdn6p2JwQ='
        );
    }, */

    delete: function() {

    }
}