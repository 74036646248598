import Vue from 'vue';
import Vuex from 'vuex';
import { List } from './modules/store-list.js';
import { JlCrud } from './modules/store-jlCrud';
import { userAcount } from './modules/store-account.js';
import { Personal } from './modules/store-personal.js';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        list: List,
        jlcrud: JlCrud,
        account: userAcount,
        person: Personal
    }
});