<template>
  <body class="page-header-fixed page-quick-sidebar-over-content">
        <div class="page-header navbar navbar-fixed-top">

            <div class="page-header-inner">

                <div class="page-logo">
                   <!-- <a href="index.html">
                        <img src="../../assets/eft/img/logos/logonuevo.png" alt="logo" class="logo-default" />
                    </a>-->
                    <h3>ADMINISTRACIÓN</h3>
                    <div class="menu-toggler sidebar-toggler hide">
                        <!-- DOC: Remove the above "hide" to enable the sidebar toggler button on header -->
                    </div>
                </div>

                <a href="javascript:;" class="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse">
                </a>

                <!-- <div class="top-menu">
                    <ul class="nav navbar-nav pull-right">

                        <li class="dropdown dropdown-user">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                <img alt="" class="img-circle hide1" src="../../assets/admin/layout/img/avatar.png" />
                                <span class="username username-hide-on-mobile"> Nick </span>
                                <i class="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                            </ul>
                        </li>
                    </ul>
                </div> -->

            </div>

        </div>

        <div class="clearfix">
        </div>

        <div class="page-container">

            <div class="page-sidebar-wrapper">
                <div class="page-sidebar navbar-collapse collapse" style="height:900px !important;overflow-y:scroll !important;overflow-x: hidden !important">

                    <ul class="page-sidebar-menu" data-auto-scroll="true" data-slide-speed="200">
                        <li class="sidebar-toggler-wrapper">
                            <div class="sidebar-toggler"></div>
                        </li>

                        <!-- <li>
                            <router-link active-class="active" to="/adminPersonal">
                                
                                <span class="title">Admin Personal</span>
                                <span class="selected"></span>
                                <span class="arrow open"></span>
                            </router-link>
                        </li> -->


                        <li v-for="functionality in functionalities" :key="functionality.Name">
                            <router-link active-class="active" :to="functionality.Name">
                                <span class="title">{{ functionality.Caption}}</span>
                                <span class="selected"></span>
                                <span class="arrow open"></span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="page-content-wrapper">
                <div class="page-content">
                    <router-view name='admin-content'></router-view>
                </div>
            </div>

        </div>

        <div class="page-footer">
            <div class="page-footer-inner">2021 &copy; Estado Fuerza by Eon Innovaction.</div>
            <div class="scroll-to-top">
                <i class="icon-arrow-up"></i>
            </div>
        </div>
  </body>
</template>

<script>
  import { EventBus } from '../../helpers/EventBus.js';
  import { Metronic } from '../../components/layout/global/metronic.js';
  import { Layout } from '../../components/layout/admin/layout.js';
  import { funcionalidades } from '../../helpers/functionalities.js';

  export default {
    name: "admin",
    data(){
        return {
        };
    },

    watch: { 
      $route(to, from) {
        console.log(to.path);
          let operation = to.path.substring(1 , to.path.length);
          if (operation == 'adminPersonal' ) {
              return;
          }

          let functionality = this.functionalities.find(element => element.Name == to.path);
          this.$store.dispatch("jlcrud/setCatalog", {
              caption : functionality.Caption,
              name:operation
          });
      }
   },
   computed: {
      functionalities(){
          //console.log(this.$store.getters['account/getCurrentUser'].funcionalidades);
         /* return this.$store.getters['account/getCurrentUser'].funcionalidades; */
         let aroPath = this.$router.currentRoute.path.split('/');
         return funcionalidades[aroPath[1]].funcionalidades;
      },
   },
    mounted() {
        jQuery(document).ready(function() {
            Metronic.init();
            Layout.init();
        });
        
    }
  };
</script>

<style lang="css">
  @import url("../../assets/admin/layout/css/themes/default.css");
  @import url("./admin.css");
</style>